var equalheight = function($container){
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = [],
        $element,
        topPosition = 0,
        currentDiv;
    $container.height('auto');
    $container.each(function() {
        $element = $(this);
        topPosition = Math.ceil($element.offset().top);
        if (currentRowStart != topPosition) {
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0;
            currentRowStart = topPosition;
            currentTallest = $element.height();
            rowDivs.push($element);
        } else {
            rowDivs.push($element);
            currentTallest = (currentTallest < $element.height()) ? ($element.height()) : (currentTallest);
        }
        for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
};