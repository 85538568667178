/*globals $*/
(function (BDM, forms) {
  'use strict';
  var settings = {},
    $el = {
      //stored elements
      form: $('form'),
      phone: $(
        'form input[name="exp-phonenumber"], form input[name="phone"], form input[name="Phone"], form input[name="PrimaryPhone"], form input[name="Phone"], form input[name="PhoneHero"], form input[name="PhoneEmbedded"]'
      ),
      select: $('select'),
      input: $('form input'),
      submitButton: $('form button[type="submit"]'),
      fields: $('form input, form select, form textarea'),
      requiredby: $('.requiredby')
    },
    focusElements = ':input:not([type="hidden"]), button, a';

  let programs;
  let newPrograms;
  /**
   *   Helper Methods
   **/

  // International phone
  function validateIntTel(phone) {
    if (phone.length) {
      Object.entries(phone).forEach(([key, value]) => {
        if (key !== 'length' && key !== 'prevObject') {
          window.intlTelInput(value, {
            utilsScript:
              'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js'
          });
        }
      });
    } else {
      window.intlTelInput(phone, {
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js'
      });
    }
  }

  function updateHeroOptions(data, service) {
    if (service == 'GetHeroInterestData') {
      $.each(data.data, function (key, value) {
        $('#HeroInterest').append(
          '<option value=' + value.Interest + '>' + value.Interest + '</option>'
        );
      });
    }
    if (service == 'GetHeroProgramsData') {
      $('#HeroProgram').find('option:not(:first)').remove();
      $.each(data.data, function (key, value) {
        $('#HeroProgram').append(
          '<option value=' + value.Program + '>' + value.Program + '</option>'
        );
      });
    }
    if (service == 'GetHeroSessionData') {
      $.each(data.data, function (key, value) {
        $('#HeroSession').append(
          '<option value=' +
          value.TERM_DESC +
          '>' +
          value.TERM_DESC +
          '</option>'
        );
      });
    }
  }

  // FIXME: This function is too long
  function updateOptions(data, service, callback) {
    if (programs !== null && data !== null) {
      //begin Form, partner, and  horizontal

      if (service == 'GetInterestData') {
        //Form
        let interest = [];
        newPrograms = [];
        $.each(programs, function (key, value) {
          if (value.LevelDescription === data?.trim()) {
            if (value.AreaOfInterest !== null) {
              interest.push(value.AreaOfInterest.trim());
              newPrograms.push(value);
            }
          }
        });
        var interestNew = interest.filter(function (element, index, self) {
          return index === self.indexOf(element);
        });
        interestNew = interestNew.sort();

        $('#AreaOfInterestFormSelected').find('option:not(:first)').remove();
        $.each(interestNew, function (key, value) {
          $('#AreaOfInterestFormSelected').append(
            $('<option></option>').attr('value', value).text(value)
          );
        });
        //Partner
        $('#AreaOfInterestPartnerSelected').find('option:not(:first)').remove();
        $.each(interestNew, function (key, value) {
          $('#AreaOfInterestPartnerSelected').append(
            $('<option></option>').attr('value', value).text(value)
          );
        });

        //Horizontal Embedded
        $('#AreaOfInterestEmbeddedSelected')
          .find('option:not(:first)')
          .remove();
        $.each(interestNew, function (key, value) {
          $('#AreaOfInterestEmbeddedSelected').append(
            $('<option></option>').attr('value', value).text(value)
          );
        });
      }
      if (service == 'GetProgramsData') {
        let programsOld = [];
        if (!newPrograms) {
          var preselectedValue = $(
            '#AreaOfInterestFormSelected option:selected'
          ).val();
          updateOptions(
            $('#DegreeTypeFormSelected option:selected').val(),
            'GetInterestData',
            function () {
              $('#AreaOfInterestFormSelected').val(preselectedValue);
            }
          );
        }
        $.each(newPrograms, function (key, value) {
          if (value.AreaOfInterest === data.trim()) {
            programsOld.push({
              name: value.ProgramName.trim(),
              code: value.ProgramCode
            });
          }
        });
        function SortByName(a, b) {
          var aName = a.name.toLowerCase();
          var bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        }
        let programsNew = programsOld.filter(function (element, index, self) {
          return index === self.indexOf(element);
        });
        programsNew = programsNew?.sort(SortByName);
        //Form
        $('#ProgramFormSelected').find('option:not(:first)').remove();
        $.each(programsNew, function (key, value) {
          $('#ProgramFormSelected').append(
            $('<option></option>').attr('value', value.code).text(value.name)
          );
        });
        //Partner
        $('#ProgramPartnerSelected').find('option:not(:first)').remove();
        $.each(programsNew, function (key, value) {
          $('#ProgramPartnerSelected').append(
            $('<option></option>').attr('value', value.code).text(value.name)
          );
        });
        //Horizontal Embedded
        $('#ProgramEmbeddedSelected').find('option:not(:first)').remove();
        $.each(programsNew, function (key, value) {
          $('#ProgramEmbeddedSelected').append(
            $('<option></option>').attr('value', value.code).text(value.name)
          );
        });
      }
      if (service == 'GetSessionData') {
        let sessionsOld = [];
        $.each(programs, function (key, value) {
          if (value.ProgramCode === data) {
            sessionsOld.push(value.SessionList);
          }
        });
        function SortByDate(a, b) {
          var aStartDate = new Date(a.startDate);
          var bStartDate = new Date(b.startDate);
          return bStartDate - aStartDate;
        }
        if (sessionsOld.length > 0 && Array.isArray(sessionsOld[0])) {
          let sessionsNew = sessionsOld[0].sort(SortByDate);
          //Form
          $('#SessionFormSelected').find('option:not(:first)').remove();
          $.each(sessionsNew, function (key, value) {
            $('#SessionFormSelected').append(
              $('<option></option>')
                .attr('value', value.TermCode)
                .text(value.Description)
            );
          });
          if (!sessionsNew.length) {
            $('#SessionFormSelected')
              .parent()
              .addClass('d-none')
              .removeClass('required');
            $('#SessionFormSelected').removeAttr('required');
          } else {
            $('#SessionFormSelected')
              .parent()
              .removeClass('d-none')
              .addClass('required');
            $('#SessionFormSelected').attr('required', '');
          }
          //Partner
          $('#SessionPartnerSelected').find('option:not(:first)').remove();
          $.each(sessionsNew, function (key, value) {
            $('#SessionPartnerSelected').append(
              $('<option></option>')
                .attr('value', value.TermCode)
                .text(value.Description)
            );
          });
          if (!sessionsNew.length) {
            $('#SessionPartnerSelected')
              .parent()
              .addClass('d-none')
              .removeClass('required');
            $('#SessionPartnerSelected').removeAttr('required');
          } else {
            $('#SessionPartnerSelected')
              .parent()
              .removeClass('d-none')
              .addClass('required');
            $('#SessionPartnerSelected').attr('required', '');
          }

          //Horizontal
          $('#SessionEmbeddedSelected').find('option:not(:first)').remove();
          $.each(sessionsNew, function (key, value) {
            $('#SessionEmbeddedSelected').append(
              $('<option></option>')
                .attr('value', value.TermCode)
                .text(value.Description)
            );
          });
          if (!sessionsNew.length) {
            $('#SessionEmbeddedSelected')
              .parent()
              .addClass('d-none')
              .removeClass('required');
            $('#SessionEmbeddedSelected').removeAttr('required');
          } else {
            $('#SessionEmbeddedSelected')
              .parent()
              .removeClass('d-none')
              .addClass('required');
            $('#SessionEmbeddedSelected').attr('required', '');
          }
        }

      }
      //end Form, Partner, and Horizontal
      //begin general
      if (service == 'GetInterestDataGeneral') {
        let interest = [];
        newPrograms = [];
        $.each(programs, function (key, value) {
          if (value.LevelDescription === data.trim()) {
            if (value.AreaOfInterest !== null) {
              interest.push(value.AreaOfInterest.trim());
              newPrograms.push(value);
            }
          }
        });
        var interestNew = interest.filter(function (element, index, self) {
          return index === self.indexOf(element);
        });
        interestNew = interestNew.sort();
        $('#AreaOfInterestSelected').find('option:not(:first)').remove();
        $.each(interestNew, function (key, value) {
          $('#AreaOfInterestSelected').append(
            $('<option></option>').attr('value', value).text(value)
          );
        });
      }
      if (service == 'GetProgramsDataGeneral') {
        let programsOld = [];

        if (!newPrograms) {
          var preselectedValue = $(
            '#AreaOfInterestSelected option:selected'
          ).val();
          updateOptions(
            $('#DegreeTypeSelected option:selected').val(),
            'GetInterestDataGeneral',
            function () {
              $('#AreaOfInterestSelected').val(preselectedValue);
            }
          );
        }
        $.each(newPrograms, function (key, value) {
          if (value.AreaOfInterest === data.trim()) {
            programsOld.push({
              name: value.ProgramName.trim(),
              code: value.ProgramCode
            });
          }
        });
        function SortByName(a, b) {
          var aName = a.name.toLowerCase();
          var bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        }
        let programsNew = programsOld.filter(function (element, index, self) {
          return index === self.indexOf(element);
        });
        programsNew = programsNew.sort(SortByName);
        $('#ProgramSelected').find('option:not(:first)').remove();
        $.each(programsNew, function (key, value) {
          $('#ProgramSelected').append(
            $('<option></option>').attr('value', value.code).text(value.name)
          );
        });
      }
      if (service == 'GetSessionDataGeneral') {
        let sessionsOld = [];
        $.each(programs, function (key, value) {
          if (value.ProgramCode === data) {
            sessionsOld.push(value.SessionList);
          }
        });
        function SortByDate(a, b) {
          var aStartDate = new Date(a.startDate);
          var bStartDate = new Date(b.startDate);
          return bStartDate - aStartDate;
        }
        let sessionsNew = sessionsOld[0].sort(SortByDate);
        $('#SessionSelected').find('option:not(:first)').remove();
        $.each(sessionsNew, function (key, value) {
          $('#SessionSelected').append(
            $('<option></option>')
              .attr('value', value.TermCode)
              .text(value.Description)
          );
        });
        if (!sessionsNew.length) {
          $('#SessionSelected')
            .parent()
            .addClass('d-none')
            .removeClass('required');
          $('#SessionSelected').removeAttr('required');
        } else {
          $('#SessionSelected')
            .parent()
            .removeClass('d-none')
            .addClass('required');
          $('#SessionSelected').attr('required', '');
        }
      }
      //end general
      //begin hero
      if (service == 'GetInterestDataHero') {
        let interest = [];
        newPrograms = [];
        $.each(programs, function (key, value) {
          if (value.LevelDescription === data.trim()) {
            if (value.AreaOfInterest !== null) {
              interest.push(value.AreaOfInterest.trim());
              newPrograms.push(value);
            }
          }
        });
        var interestNew = interest.filter(function (element, index, self) {
          return index === self.indexOf(element);
        });
        interestNew = interestNew.sort();
        $('#AreaOfInterestHeroSelected').find('option:not(:first)').remove();
        $.each(interestNew, function (key, value) {
          $('#AreaOfInterestHeroSelected').append(
            $('<option></option>').attr('value', value).text(value)
          );
        });
      }
      if (service == 'GetProgramsDataHero') {
        let programsOld = [];
        if (!newPrograms) {
          var preselectedValue = $(
            '#AreaOfInterestHeroSelected option:selected'
          ).val();
          updateOptions(
            $('#DegreeTypeHeroSelected option:selected').val(),
            'GetInterestDataHero',
            function () {
              $('#AreaOfInterestHeroSelected').val(preselectedValue);
            }
          );
        }
        $.each(newPrograms, function (key, value) {
          if (value.AreaOfInterest === data) {
            programsOld.push({
              name: value.ProgramName.trim(),
              code: value.ProgramCode
            });
          }
        });
        function SortByName(a, b) {
          var aName = a.name.toLowerCase();
          var bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        }
        let programsNew = programsOld.filter(function (element, index, self) {
          return index === self.indexOf(element);
        });
        programsNew = programsNew.sort(SortByName);
        $('#ProgramHeroSelected').find('option:not(:first)').remove();
        $.each(programsNew, function (key, value) {
          $('#ProgramHeroSelected').append(
            $('<option></option>').attr('value', value.code).text(value.name)
          );
        });
      }
      if (service == 'GetSessionDataHero') {
        let sessionsOld = [];
        $.each(programs, function (key, value) {
          if (value.ProgramCode === data) {
            sessionsOld.push(value.SessionList);
          }
        });
        function SortByDate(a, b) {
          var aStartDate = new Date(a.startDate);
          var bStartDate = new Date(b.startDate);
          return bStartDate - aStartDate;
        }
        let sessionsNew = sessionsOld[0].sort(SortByDate);
        $('#SessionHeroSelected').find('option:not(:first)').remove();
        $.each(sessionsNew, function (key, value) {
          $('#SessionHeroSelected').append(
            $('<option></option>')
              .attr('value', value.TermCode)
              .text(value.Description)
          );
        });
        if (!sessionsNew.length) {
          $('#SessionHeroSelected')
            .parent()
            .addClass('d-none')
            .removeClass('required');
          $('#SessionHeroSelected').removeAttr('required');
        } else {
          $('#SessionHeroSelected')
            .parent()
            .removeClass('d-none')
            .addClass('required');
          $('#SessionHeroSelected').attr('required', '');
        }
      }
      //end hero
    }
    if (callback) callback();
  }
  // FIXME: This function is too long

  /// Setup Events
  function bindEvents() {
    var waitSessionStorage;

    const localPrograms = window.sessionStorage.getItem('programs');
    programs = JSON.parse(localPrograms);

    function checkPrograms() {
      const localPrograms = window.sessionStorage.getItem('programs');
      if (localPrograms !== null) {
        programs = JSON.parse(localPrograms);
        window.clearInterval(waitSessionStorage);
      }
    }

    if (programs === null) {
      waitSessionStorage = window.setInterval(checkPrograms, 2000);
    }

    if ($el.phone) {
      validateIntTel($el.phone);
    }

    // FIXME: Same code for different forms ?
    //Begin general
    if ($('#DegreeTypeSelected').length) {
      $('#DegreeTypeSelected').on('change', function () {
        var degreeType = $('#DegreeTypeSelected option:selected').val();
        $('#nurse-registered-general-lead').css('display', 'none');
        $('#nurse-registered-no-message-general-lead').css('display', 'none');
        $('#nurse-registered-general-lead').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary').prop('disabled', false);
        updateOptions(degreeType, 'GetInterestDataGeneral');
      });
    }
    if ($('#AreaOfInterestSelected').length) {
      $('#AreaOfInterestSelected').on('change', function () {
        var interest = $('#AreaOfInterestSelected option:selected').val();
        $('#nurse-registered-general-lead').css('display', 'none');
        $('#nurse-registered-no-message-general-lead').css('display', 'none');
        $('#nurse-registered-general-lead').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary').prop('disabled', false);
        updateOptions(interest, 'GetProgramsDataGeneral');
      });
    }

    $(
      '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
    ).change(function () {
      var program = $('#ProgramSelected option:selected').val();

      if ($(this).val() == 'No') {
        $('#nurse-registered-no-message-general-lead').css('display', 'block');
        $('button.btn.btn-primary').prop('disabled', true);
      } else {
        $('#nurse-registered-no-message-general-lead').css('display', 'none');
        $('button.btn.btn-primary').prop('disabled', false);
        updateOptions(program, 'GetSessionDataGeneral');
      }
    });

    if ($('#ProgramSelected').length) {
      $('#ProgramSelected').on('change', function () {
        var nursingProgramCode = $('#nursingProgramCodes').val();
        var program = $('#ProgramSelected option:selected').val();
        if (nursingProgramCode) {
          var arr = nursingProgramCode.split(',');
          if (arr.includes(program)) {
            $('#nurse-registered-general-lead').css('display', 'block');
            $('#nurse-registered-general-lead').addClass('required');
            $(
              '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
            ).prop('required', true);
          } else {
            $('#nurse-registered-general-lead').css('display', 'none');
            $('#nurse-registered-no-message-general-lead').css(
              'display',
              'none'
            );
            $('#nurse-registered-general-lead').removeClass(
              'required has-error has-error__missing'
            );
            $(
              '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
            ).prop('required', false);
            $(
              '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
            ).removeAttr('checked');
            $('button.btn.btn-primary.submit__tabs').prop('disabled', false);

            updateOptions(program, 'GetSessionDataGeneral');
          }
        } else {
          $('#nurse-registered-general-lead').css('display', 'none');
          $('#nurse-registered-no-message-general-lead').css('display', 'none');
          $('#nurse-registered-general-lead').removeClass(
            'required has-error has-error__missing'
          );
          $(
            '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
          ).prop('required', false);
          $(
            '#nurse-registered-general-lead input[type=radio][name=RegisteredNurse]'
          ).removeAttr('checked');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionDataGeneral');
        }
      });
    }
    //end general
    //begin Form

    //A/B TEST Request Info Form

    if ($('.degreeTypeFormBtn').length) {
      $('.degreeTypeFormBtn').eq(0).trigger('click');
      $('.degreeTypeFormBtn').on('click', function () {
        var degreeType = $(this).data('value');
        $(this)
          .addClass('fullbtn-primary-carat')
          .siblings()
          .removeClass('fullbtn-primary-carat');
        $('#nurse-registered').css('display', 'none');
        $('#nurse-registered-no-message').css('display', 'none');
        $('#nurse-registered').removeClass(
          'required has-error has-error__missing'
        );
        $('#nurse-registered input[type=radio][name=RegisteredNurse]').prop(
          'required',
          false
        );
        $(
          '#nurse-registered input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(degreeType, 'GetInterestData');
      });
    }

    if ($('#DegreeTypeFormSelected').length) {
      $('#DegreeTypeFormSelected').on('change', function () {
        var degreeType = $('#DegreeTypeFormSelected option:selected').val();
        $('#nurse-registered').css('display', 'none');
        $('#nurse-registered-no-message').css('display', 'none');
        $('#nurse-registered').removeClass(
          'required has-error has-error__missing'
        );
        $('#nurse-registered input[type=radio][name=RegisteredNurse]').prop(
          'required',
          false
        );
        $(
          '#nurse-registered input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(degreeType, 'GetInterestData');
      });
    }
    if ($('#AreaOfInterestFormSelected').length) {
      $('#AreaOfInterestFormSelected').on('change', function () {
        var interest = $('#AreaOfInterestFormSelected option:selected').val();
        $('#nurse-registered').css('display', 'none');
        $('#nurse-registered-no-message').css('display', 'none');
        $('#nurse-registered').removeClass(
          'required has-error has-error__missing'
        );
        $('#nurse-registered input[type=radio][name=RegisteredNurse]').prop(
          'required',
          false
        );
        $(
          '#nurse-registered input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(interest, 'GetProgramsData');
      });
    }

    $('#nurse-registered input[type=radio][name=RegisteredNurse]').change(
      function () {
        var program = $('#ProgramFormSelected option:selected').val();

        if ($(this).val() == 'No') {
          $('#nurse-registered-no-message').css('display', 'block');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', true);
        } else {
          $('#nurse-registered-no-message').css('display', 'none');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionData');
        }
      }
    );

    if ($('#ProgramFormSelected').length) {
      $('#ProgramFormSelected').on('change', function () {
        var nursingProgramCode = $('#nursingProgramCodes').val();
        var program = $('#ProgramFormSelected option:selected').val();
        if (nursingProgramCode) {
          var arr = nursingProgramCode.split(',');
          if (arr.includes(program)) {
            $('#nurse-registered').css('display', 'block');
            $('#nurse-registered').addClass('required');
            $('#nurse-registered input[type=radio][name=RegisteredNurse]').prop(
              'required',
              true
            );
          } else {
            $('#nurse-registered').css('display', 'none');
            $('#nurse-registered-no-message').css('display', 'none');
            $('#nurse-registered').removeClass(
              'required has-error has-error__missing'
            );
            $('#nurse-registered input[type=radio][name=RegisteredNurse]').prop(
              'required',
              false
            );
            $(
              '#nurse-registered input[type=radio][name=RegisteredNurse]'
            ).removeAttr('checked');
            $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
            updateOptions(program, 'GetSessionData');
          }
        } else {
          $('#nurse-registered').css('display', 'none');
          $('#nurse-registered-no-message').css('display', 'none');
          $('#nurse-registered').removeClass(
            'required has-error has-error__missing'
          );
          $('#nurse-registered input[type=radio][name=RegisteredNurse]').prop(
            'required',
            false
          );
          $(
            '#nurse-registered input[type=radio][name=RegisteredNurse]'
          ).removeAttr('checked');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionData');
        }
      });
    }
    //end form
    //begin Hero
    if ($('#DegreeTypeHeroSelected').length) {
      $('#DegreeTypeHeroSelected').on('change', function () {
        var degreeType = $('#DegreeTypeHeroSelected option:selected').val();
        $('#nurse-registered-hero').css('display', 'none');
        $('#nurse-registered-no-message-hero').css('display', 'none');
        $('#nurse-registered-hero').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-alt-carat.submit__tabs').prop('disabled', false);
        updateOptions(degreeType, 'GetInterestDataHero');
      });
    }
    if ($('#AreaOfInterestHeroSelected').length) {
      $('#AreaOfInterestHeroSelected').on('change', function () {
        var interest = $('#AreaOfInterestHeroSelected option:selected').val();
        $('#nurse-registered-hero').css('display', 'none');
        $('#nurse-registered-no-message-hero').css('display', 'none');
        $('#nurse-registered-hero').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-alt-carat.submit__tabs').prop('disabled', false);
        updateOptions(interest, 'GetProgramsDataHero');
      });
    }

    $('#nurse-registered-hero input[type=radio][name=RegisteredNurse]').change(
      function () {
        var program = $('#ProgramHeroSelected option:selected').val();

        if ($(this).val() == 'No') {
          $('#nurse-registered-no-message-hero').css('display', 'block');
          $('button.btn.btn-alt-carat.submit__tabs').prop('disabled', true);
        } else {
          $('#nurse-registered-no-message-hero').css('display', 'none');
          $('button.btn.btn-alt-carat.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionDataHero');
        }
      }
    );

    if ($('#ProgramHeroSelected').length) {
      $('#ProgramHeroSelected').on('change', function () {
        var nursingProgramCode = $('#nursingProgramCodes').val();
        var program = $('#ProgramHeroSelected option:selected').val();

        if (nursingProgramCode) {
          var arr = nursingProgramCode.split(',');
          if (arr.includes(program)) {
            $('#nurse-registered-hero').css('display', 'block');
            $('#nurse-registered-hero').addClass('required');
            $(
              '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
            ).prop('required', true);
          } else {
            $('#nurse-registered-hero').css('display', 'none');
            $('#nurse-registered-no-message-hero').css('display', 'none');
            $('#nurse-registered-hero').removeClass(
              'required has-error has-error__missing'
            );
            $(
              '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
            ).prop('required', false);
            $(
              '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
            ).removeAttr('checked');
            $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
            updateOptions(program, 'GetSessionDataHero');
          }
        } else {
          $('#nurse-registered-hero').css('display', 'none');
          $('#nurse-registered-no-message-hero').css('display', 'none');
          $('#nurse-registered-hero').removeClass(
            'required has-error has-error__missing'
          );
          $(
            '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
          ).prop('required', false);
          $(
            '#nurse-registered-hero input[type=radio][name=RegisteredNurse]'
          ).removeAttr('checked');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionDataHero');
        }
      });
    }
    // end Hero
    // begin embedded form
    if ($('#DegreeTypeEmbeddedSelected').length) {
      $('#DegreeTypeEmbeddedSelected').on('change', function () {
        var degreeType = $('#DegreeTypeEmbeddedSelected option:selected').val();
        $('#nurse-registered-embedded-portal').css('display', 'none');
        $('#nurse-registered-no-message-embedded-portal').css(
          'display',
          'none'
        );
        $('#nurse-registered-embedded-portal').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(degreeType, 'GetInterestData');
      });
    }
    if ($('#AreaOfInterestEmbeddedSelected').length) {
      $('#AreaOfInterestEmbeddedSelected').on('change', function () {
        var interest = $(
          '#AreaOfInterestEmbeddedSelected option:selected'
        ).val();
        $('#nurse-registered-embedded-portal').css('display', 'none');
        $('#nurse-registered-no-message-embedded-portal').css(
          'display',
          'none'
        );
        $('#nurse-registered-embedded-portal').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(interest, 'GetProgramsData');
      });
    }

    $(
      '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
    ).change(function () {
      var program = $('#ProgramEmbeddedSelected option:selected').val();

      if ($(this).val() == 'No') {
        $('#nurse-registered-no-message-embedded-portal').css(
          'display',
          'block'
        );
        $('button.btn.btn-primary.submit__tabs').prop('disabled', true);
      } else {
        $('#nurse-registered-no-message-embedded-portal').css(
          'display',
          'none'
        );
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(program, 'GetSessionData');
      }
    });

    if ($('#ProgramEmbeddedSelected').length) {
      $('#ProgramEmbeddedSelected').on('change', function () {
        var nursingProgramCode = $('#nursingProgramCodes').val();
        var program = $('#ProgramEmbeddedSelected option:selected').val();
        if (nursingProgramCode) {
          var arr = nursingProgramCode.split(',');
          if (arr.includes(program)) {
            $('#nurse-registered-embedded-portal').css(
              'display',
              'inline-block'
            );
            $('#nurse-registered-embedded-portal').addClass('required');
            $(
              '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
            ).prop('required', true);
          } else {
            $('#nurse-registered-embedded-portal').css('display', 'none');
            $('#nurse-registered-no-message-embedded-portal').css(
              'display',
              'none'
            );
            $('#nurse-registered-embedded-portal').removeClass(
              'required has-error has-error__missing'
            );
            $(
              '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
            ).prop('required', false);
            $(
              '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
            ).removeAttr('checked');
            $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
            updateOptions(program, 'GetSessionData');
          }
        } else {
          $('#nurse-registered-embedded-portal').css('display', 'none');
          $('#nurse-registered-no-message-embedded-portal').css(
            'display',
            'none'
          );
          $('#nurse-registered-embedded-portal').removeClass(
            'required has-error has-error__missing'
          );
          $(
            '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
          ).prop('required', false);
          $(
            '#nurse-registered-embedded-portal input[type=radio][name=RegisteredNurse]'
          ).removeAttr('checked');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionData');
        }
      });
    }
    // end embedded

    // begin partner
    if ($('#DegreeTypePartnerSelected').length) {
      $('#DegreeTypePartnerSelected').on('change', function () {
        // var zipCode = $('#zipcode').val();
        var degreeType = $('#DegreeTypePartnerSelected option:selected').val();
        $('#nurse-registered-partner-portal').css('display', 'none');
        $('#nurse-registered-no-message-partner-portal').css('display', 'none');
        $('#nurse-registered-partner-portal').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(degreeType, 'GetInterestData');
      });
    }
    if ($('#AreaOfInterestPartnerSelected').length) {
      $('#AreaOfInterestPartnerSelected').on('change', function () {
        // var zipCode = $('#zipcode').val();
        // var degreeType = $('#DegreeTypePartnerSelected option:selected').val();
        var interest = $(
          '#AreaOfInterestPartnerSelected option:selected'
        ).val();
        $('#nurse-registered-partner-portal').css('display', 'none');
        $('#nurse-registered-no-message-partner-portal').css('display', 'none');
        $('#nurse-registered-partner-portal').removeClass(
          'required has-error has-error__missing'
        );
        $(
          '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
        ).prop('required', false);
        $(
          '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
        ).removeAttr('checked');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(interest, 'GetProgramsData');
      });
    }

    $(
      '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
    ).change(function () {
      // var zipCode = $('#zipcode').val(),
      //   degreeType = $('#DegreeTypePartnerSelected option:selected').val(),
      //   interest = $('#AreaOfInterestPartnerSelected option:selected').val(),
      var program = $('#ProgramPartnerSelected option:selected').val();

      if ($(this).val() == 'No') {
        $('#nurse-registered-no-message-partner-portal').css(
          'display',
          'block'
        );
        $('button.btn.btn-primary.submit__tabs').prop('disabled', true);
      } else {
        $('#nurse-registered-no-message-partner-portal').css('display', 'none');
        $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
        updateOptions(program, 'GetSessionData');
      }
    });

    if ($('#ProgramPartnerSelected').length) {
      $('#ProgramPartnerSelected').on('change', function () {
        var nursingProgramCode = $('#nursingProgramCodes').val();
        // var zipCode = $('#zipcode').val();
        // var degreeType = $('#DegreeTypePartnerSelected option:selected').val();
        // var interest = $(
        //   '#AreaOfInterestPartnerSelected option:selected'
        // ).val();
        var program = $('#ProgramPartnerSelected option:selected').val();
        if (nursingProgramCode) {
          var arr = nursingProgramCode.split(',');
          if (arr.includes(program)) {
            $('#nurse-registered-partner-portal').css(
              'display',
              'inline-block'
            );
            $('#nurse-registered-partner-portal').addClass('required');
            $(
              '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
            ).prop('required', true);
          } else {
            $('#nurse-registered-partner-portal').css('display', 'none');
            $('#nurse-registered-no-message-partner-portal').css(
              'display',
              'none'
            );
            $('#nurse-registered-partner-portal').removeClass(
              'required has-error has-error__missing'
            );
            $(
              '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
            ).prop('required', false);
            $(
              '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
            ).removeAttr('checked');
            $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
            updateOptions(program, 'GetSessionData');
          }
        } else {
          $('#nurse-registered-partner-portal').css('display', 'none');
          $('#nurse-registered-no-message-partner-portal').css(
            'display',
            'none'
          );
          $('#nurse-registered-partner-portal').removeClass(
            'required has-error has-error__missing'
          );
          $(
            '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
          ).prop('required', false);
          $(
            '#nurse-registered-partner-portal input[type=radio][name=RegisteredNurse]'
          ).removeAttr('checked');
          $('button.btn.btn-primary.submit__tabs').prop('disabled', false);
          updateOptions(program, 'GetSessionData');
        }
      });
    }
    // end partner
    // FIXME: Same code for different forms ?

    if ($el.submitButton.length) {
      $el.submitButton.on('click', function (event) {
        var $form = $(this).closest('form');
        var $hiddenField = $(this).closest('form').find('.recaptcha-value');
        var $siteKey = $(this)
          .closest('form')
          .find('input[name="siteKey"]')
          .val();
        var $formDescription = $(this).closest('form').attr('name');
        event.preventDefault();
        if ($(this).hasClass('submit__tabs')) {
          var $fields = $(this)
            .closest('.tabs__content')
            .find(':input:not([type=hidden])');
          if (
            $(this).closest('.tabs__content')[0] ===
            $form.find('.tabs__content').last()[0]
          ) {
            if (forms.validateAll($fields)) {
              $('.btn').attr('disabled', true);
              $(this).attr('disabled', true);
            }
            sendTokenAndSubmitForm(
              $hiddenField,
              $siteKey,
              $formDescription,
              $form
            );
          } else {
            if (forms.validateAll($fields)) {
              var tab = $(this)
                .closest('.tabs__wrapper')
                .find('.form-arrows li.active')
                .next('li')
                .index();
              $(this)
                .closest('.tabs__wrapper')
                .find('.form-arrows li.active a')
                .append(
                  '<span class="sr-only visually-hidden">Completed</span>'
                );
              $(this).closest('.tabs__content').addClass('complete');

              $(this)
                .closest('.tabs__wrapper')
                .find('.nav-tabs li')
                .eq(tab)
                .trigger('click');
              if (
                $(this)
                  .closest('.tabs__wrapper')
                  .find('.tabs__content')
                  .eq(tab)
                  .find(focusElements).length
              ) {
                $(this)
                  .closest('.tabs__wrapper')
                  .find('.tabs__content')
                  .eq(tab)
                  .find(focusElements)
                  .first()[0]
                  .focus();
              }
            } else {
              $(this).closest('.tabs__content').removeClass('complete');
            }
          }
        } else {
          var $fields = $form.find(':input:not([type=hidden])');
          if (forms.validateAll($fields)) {
            $('.btn').attr('disabled', true);
            $form.submit();
            $(this).attr('disabled', true);
          }
          submitForm($form);
        }
      });
    }
    if ($el.form.length) {
      $el.form.children().keydown(function (event) {
        if (event.keyCode === 13) {
          $(event.target).find('button[type="submit"]').trigger('click');
        }
      });
    }
    if ($el.phone.length) {
      $el.phone.on('input', function (e) {
        var value = e.target.value.replace(/[^\d+()]/g, '');
        var pattern = 'Z000009999999999';
        var result = '';
        var valueIndex = 0;

        for (var i = 0; i < pattern.length && valueIndex < value.length; i++) {
          if (pattern[i] === 'Z') {
            if (value[valueIndex].match(/[+(\d]/)) {
              result += value[valueIndex];
              valueIndex++;
            }
          } else if (
            (pattern[i] === '0' || pattern[i] === '9') &&
            value[valueIndex].match(/\d/)
          ) {
            result += value[valueIndex];
            valueIndex++;
          }
        }

        e.target.value = result;
      });
    }
    if ($el.select.length) {
      $el.select.on('change', function (event) {
        var $requiredby = $el.requiredby.filter(
          '[data-requiredby="' + $(event.target).attr('id') + '"]'
        );
        if ($requiredby.length) {
          if (
            $requiredby.attr('data-requiredvalue') === $(event.target).val()
          ) {
            $requiredby.addClass('active');
            $requiredby.find('select').attr('required', true);
          } else {
            $requiredby.removeClass('active');
            $requiredby.find('select').attr('required', false);
          }
        }
        setTimeout(function () {
          event.target.focus();
        }, 50);
      });
    }
    if ($('#email').length) {
      $('#email').keydown(function (event) {
        $('#beEmailError').css({
          display: 'none'
        });
      });
    }
    if ($('#username').length) {
      $('#username').on('keydown', function () {
        $('#beUsernameError').css({
          display: 'none'
        });
      });
    }
  }

  function validateField($field, e) {
    var valid = true;

    if ($field.is('select')) {
      if ($field.val()) {
        $field.closest('.form-group').addClass('select-set');
      } else {
        $field.closest('.form-group').removeClass('select-set');
      }
    }
    if (e && (e.type === 'blur' || e.type === 'focusout')) {
      $field[0].fieldInit = true;
    }

    if ($field[0].fieldInit) {
      $field[0].fieldValid = forms.validateField($field);
      if (!$field[0].fieldValid.value) {
        var randomID = 'error' + new Date().getTime();
        valid = false;
        $field.closest('.form-group').addClass('has-error');
        $field
          .closest('.form-group')
          .removeClass(
            'has-error__missing has-error__invalid has-error__length has-error__match has-error__nodata'
          );
        if ($field[0].fieldValid.reason === 'missing') {
          $field.closest('.form-group').addClass('has-error__missing');
          $field
            .closest('.form-group')
            .find('.forms__message--missing')
            .attr('id', randomID);
          $field.attr('aria-describedby', randomID);
        } else if ($field[0].fieldValid.reason === 'length') {
          $field.closest('.form-group').addClass('has-error__length');
          $field
            .closest('.form-group')
            .find('.forms__message--length')
            .attr('id', randomID);
          $field.attr('aria-describedby', randomID);
        } else if ($field[0].fieldValid.reason === 'match') {
          $field.closest('.form-group').addClass('has-error__match');
          $field
            .closest('.form-group')
            .find('.forms__message--match')
            .attr('id', randomID);
          $field.attr('aria-describedby', randomID);
        } else if ($field[0].fieldValid.reason === 'has-error') {
          $field.closest('.form-group').addClass('has-error__invalid');
          $field
            .closest('.form-group')
            .find('.forms__message--invalid')
            .attr('id', randomID);
          $field.attr('aria-describedby', randomID);
        }
      } else {
        forms.resetField($field);
      }
    }
    return valid;
  }

  function submitForm($form) {
    var $source = $(
      'input[type=hidden][name=source][data-value=attributionSource]'
    )[0];
    var $fields = $form.find(':input:not([type=hidden])');
    var $phoneInput = $form.find('input').filter(function () {
      return this.name.toLowerCase() === "phone";
    });

    if ($form.find('input[name="PhoneHero"]').val()) {
      $phoneInput = $form.find('input[name="PhoneHero"]');
    }
    if ($form.find('input[name="PhoneEmbedded"]').val()) {
      $phoneInput = $form.find('input[name="PhoneEmbedded"]');
    }
    if (forms.validateAll($fields)) {
      let iti = window.intlTelInputGlobals.getInstance($phoneInput[0]);
      $phoneInput.val(iti.getNumber());
      if ($source != undefined) {
        $form.append($source);
      }
      $form.submit();
      $(this).attr('disabled', true);
    }
  }

  function sendTokenAndSubmitForm(hiddenToken, siteKey, formName, form) {
    grecaptcha.ready(function () {
      grecaptcha.execute(siteKey, { action: formName }).then(function (token) {
        hiddenToken.val(token);
        if (formName === 'RequestInfoForm') {
          getHeroInfoFormStep2('requestInfoFormContainer');
        }
        submitForm(form);
      });
    });
  }

  forms.bindValidation = function (fields) {
    var $field;
    Object.keys(fields).forEach(function (value, key) {
      $field = fields.eq(key);
      if ($field.length) {
        $field[0].fieldInit = false;
        $field.on('keyup blur change', function (e) {
          validateField($(this), e);
        });
      }
    });
  };

  /**
   * Displays errors associated with form fields returned from the API.
   *
   * @method displayErrors
   */
  forms.displayErrors = function ($form, messages) {
    if ($form.length && $.type(messages) === 'array') {
      messages.forEach(function (message) {
        forms.has -
          errorateField(
            $form.find('[name="' + message.fieldId + '"]'),
            message.errorCode
          );
      });
    }
  };

  forms.resetAll = function (fields) {
    Object.keys(fields).forEach(function (key) {
      forms.resetField(fields[key]);
    });
  };

  forms.resetField = function ($field) {
    if ($field[0].fieldInit) {
      $field
        .closest('.form-group')
        .removeClass(
          'has-error has-error__missing has-error__invalid has-error__length has-error__match has-error__nodata'
        );
    }
  };

  forms.validateAll = function (fields) {
    var valid = true,
      $field;
    Object.keys(fields).forEach(function (value, key) {
      $field = fields.eq(key);
      if ($field.length) {
        $field[0].fieldInit = true;
        if (validateField($field, null) === false) {
          valid = false;
        }
      }
    });
    return valid;
  };

  forms.validateField = function ($input) {
    if ($input.attr('data-length')) {
      var leng = $input.attr('data-length').split(',');
      if (
        !$input.val() ||
        $input.val().length < parseInt(leng[0], 10) ||
        $input.val().length > parseInt(leng[1], 10)
      ) {
        return {
          value: false,
          reason: 'length'
        };
      }
    }
    if ($input.attr('data-match')) {
      if ($input.val() !== $('#' + $input.attr('data-match')).val()) {
        return {
          value: false,
          reason: 'match'
        };
      }
    }
    if ($input.is('textarea')) {
      if ($input.attr('required') && $input.val() === '') {
        return {
          value: false,
          reason: 'missing'
        };
      }
      if (!$input.attr('pattern')) {
        return {
          value: true,
          reason: null
        };
      }
      var pattern = new RegExp($input.attr('pattern')),
        error = false;
      $.each($input.val().split('\n'), function () {
        var hasError = !this.match(pattern);
        if (this && hasError) {
          error = true;
        }
      });
      if (error) {
        return {
          value: false,
          reason: 'has-error'
        };
      }
    } else {
      if (typeof $input[0].validity !== 'undefined') {
        var valueMissing = $input[0].validity.valueMissing;
        if (!$input[0].validity.valid) {
          return {
            value: false,
            reason: valueMissing ? 'missing' : 'has-error'
          };
        }
      }
    }
    return {
      value: true,
      reason: null
    };
  };

  forms.allowNumbers = function (e) {
    if (!$(this).attr('data-allownumbers')) {
      if (
        $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (e.keyCode === 65 && e.ctrlKey === true) ||
        (e.keyCode === 67 && e.ctrlKey === true) ||
        (e.keyCode === 88 && e.ctrlKey === true) ||
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }
      if (
        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }
    }
  };

  /**
   *   Public Methods
   **/

  forms.init = function () {
    if ($el.form.length) {
      forms.bindValidation($el.fields);
      bindEvents();
    }
  };
})(
  (window.BDM = window.BDM || {}),
  (window.BDM.forms = window.BDM.forms || {})
);

// Change checkbox value yes/no
function checkboxValue(element) {
  $(element).change(function () {
    if ($(this).is(':checked')) {
      $(this).val('yes');
    } else if ($(this).not(':checked')) {
      $(this).val('no');
    }
  });
}

// Horizontal Form Tabs

$(document).on('click', '.continue', function () {
  var zc = $('.zc').val();
  var complete = $('.forms__request-step.tabs__content').hasClass('complete');
  if (zc == '') {
    $('.zc').closest('.form-group').addClass('has-error has-error__missing');
  } else {
    $('.zc').closest('.form-group').removeClass('has-error has-error__missing');
  }
  // Check if step1 is completed
  if (complete == true) {
    $('.tab-second').removeClass('disabled');
  } else {
    $('.tab-second').addClass('disabled');
  }
});

function clearDropdowns() {
  $('#DegreeTypeFormSelected').val('');
  $('#AreaOfInterestFormSelected').find('option').not(':first').remove();
  $('#ProgramFormSelected').find('option').not(':first').remove();
  $('#SessionFormSelected').find('option').not(':first').remove();
  $(
    '#DegreeTypeFormSelected, #AreaOfInterestFormSelected, #ProgramFormSelected, #SessionFormSelected'
  )
    .closest('.form-group')
    .removeClass('has-error has-error__missing');
}

function resetDropdowns(e) {
  if (e.key === 'Enter') {
    clearDropdowns();
    e.preventDefault();
    return false;
  }
}

function getEmailFormChange() {
  var email = $('#email').val();
  window.dataLayer.push({
    event: 'formChange-email',
    email: email
  });
}

function getZipCodeFormChange() {
  var zipCode = $('input[name="zipcode"]').val();
  window.dataLayer.push({
    event: 'formChange-zipCode',
    zipCode: zipCode
  });
}

function getDegreeTypeFormChange(e) {
  var degreeType = '';
  if ($(e.target).is('a')) {
    degreeType = e.data('value');
  } else if ($(e.target).is('select')) {
    degreeType = $('#DegreeTypeFormSelected').val();
  }
  window.dataLayer.push({
    event: 'formChange-degreeType',
    degreeType: degreeType
  });
}

function getAreaOfInterestFormChange() {
  var areaOfInterest = $('#AreaOfInterestFormSelected').val();
  window.dataLayer.push({
    event: 'formChange-areaOfInterest',
    areaOfInterest: areaOfInterest
  });
}

function getProgramFormChange() {
  var program = $('#ProgramFormSelected').val();
  window.dataLayer.push({
    event: 'formChange-program',
    program: program
  });
}

function getSessionFormChange() {
  var session = $('#SessionFormSelected').val();
  window.dataLayer.push({
    event: 'formChange-session',
    session: session
  });
}

function getFirstNameFormChange() {
  var firstName = $('input[name="FirstName"]').val();
  window.dataLayer.push({
    event: 'formChange-firstName',
    firstName: firstName
  });
}

function getLastNameFormChange() {
  var lastName = $('input[name="LastName"]').val();
  window.dataLayer.push({
    event: 'formChange-lastName',
    lastName: lastName
  });
}

function getPhoneFormChange() {
  var phone = $('input[name="Phone"]').val();
  window.dataLayer.push({
    event: 'formChange-phone',
    phone: phone
  });
}

function getTermsFormChange() {
  var terms = $('input[name="TermsAndConditions"]').val();
  window.dataLayer.push({
    terms: terms
  });
}

function getHeroInfoFormStep1() {
  var $zipCode = $('input[name="zipcode"]');
  var $degreeType = $('#DegreeTypeFormSelected');
  var $areaOfInterest = $('#AreaOfInterestFormSelected');
  var $program = $('#ProgramFormSelected');
  var $session = $('#SessionFormSelected');

  if (
    $zipCode.length !== 0 &&
    $degreeType.length !== 0 &&
    $areaOfInterest.length !== 0 &&
    $program.length !== 0 &&
    $session.length !== 0
  ) {
    var zipCode = $zipCode.val();
    var degreeType = $degreeType.val();
    var areaOfInterest = $areaOfInterest.val();
    var program = $program.val();
    var session = $session.val();

    var data_object = {
      event: 'form',
      formStep: 'step1',
      formName: 'requestInfo',
      zipCode: zipCode,
      degreeType: degreeType,
      areaOfInterest: areaOfInterest,
      program: program,
      session: session
    };

    window.dataLayer.push(data_object);
    var data_layer_cookie = '[';
    data_layer_cookie += JSON.stringify(data_object);
    setCookie('brandman-data-layer', data_layer_cookie, 7);
  }
}

function getHeroInfoFormStep2(id) {
  var $firstName = $('#' + id + ' [name="FirstName"]');
  var $lastName = $('#' + id + ' [name="LastName"]');
  var $email = $('#' + id + ' [name="Email"]');
  var $phone = $('#' + id + ' [name="Phone"]');
  var $terms = $('#' + id + ' ');

  if (
    $firstName.length !== 0 &&
    $lastName.length !== 0 &&
    $email.length !== 0 &&
    $phone.length !== 0
  ) {
    var firstName = $firstName.val();
    var lastName = $lastName.val();
    var email = $email.val();
    var phone = $phone.val();
    var terms = $terms.val();

    var aboutValid = AboutValidFields(email, phone);
    if (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      phone !== '' &&
      aboutValid
    ) {
      var data_object = {
        event: 'form',
        formStep: 'step2',
        formName: 'requestInfo',
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        terms: terms
      };

      window.dataLayer.push(data_object);

      var data_layer_cookie = getCookie('brandman-data-layer');
      data_layer_cookie += ',';
      data_layer_cookie += JSON.stringify(data_object);
      data_layer_cookie += ']';

      setCookie('brandman-data-layer', data_layer_cookie, 7);

      $("button.submit__tabs[type='button']").addClass('disabled');
      setTimeout(function () {
        $('form.tabs__container').submit();
      }, 5000);
    } else {
      AboutFieldsValidation($firstName, $lastName, $email, $phone);
      $("button.submit__tabs[type='button']").on('click', function (event) {
        event.preventDefault();
      });
    }
  }
}

function AboutFieldsValidation(firstname, lastname, email, phone) {
  if (firstname.val() == '') {
    $('#FistName').addClass(' has-error has-error__missing');
  } else {
    $('#FistName').removeClass(' has-error has-error__missing');
  }

  if (lastname.val() == '') {
    $('#LastName').addClass(' has-error has-error__missing');
  } else {
    $('#LastName').removeClass(' has-error has-error__missing');
  }

  if (email.val() == '') {
    $('#Email').addClass(' has-error has-error__missing');
  } else {
    $('#Email').removeClass(' has-error has-error__missing');
  }

  if (phone.val() == '') {
    $('#Phone').addClass(' has-error has-error__missing');
  } else {
    $('#Phone').removeClass(' has-error has-error__missing');
  }
}

function AboutValidFields(email, phone) {
  var emailValid =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  var phoneValid = /^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/;
  if (!email.match(emailValid) || !phone.match(phoneValid)) {
    return false;
  } else {
    return true;
  }
}

//load when ready
$(function () {
  window.BDM.forms.init();

  checkboxValue('input[name="horizontalpredefined"]');
  checkboxValue('input[name="generalleadpredefined"]');
});
