/*globals $*/
(function(BDM, videoGallery) {
    'use strict';

    var settings = {
            dataurl: "https://www.googleapis.com/youtube/v3/videos",
            youtubekey: 'AIzaSyC6eo3wLg5BEQ0MqDwxxqT2Hwi6xbH4BVk'
        },

        $el = { //stored elements
            gallery: $('.video-gallery'),
            thumbs: $('.video-gallery__thumb'),
            player: $('.video-gallery__player'),
            play: $('.video-gallery__player-play')
        },

        players = [],

        ids = [],

        videoData,

        GoogleAuth;
    /**
     *   Helper Methods
     **/

    function setup() {
        var tag = document.createElement('script'),
            youtubeTag = document.createElement('script'),
            firstScriptTag = document.getElementsByTagName('script')[0],
            title;
        tag.src = "https://apis.google.com/js/api.js";
        youtubeTag.src = "https://www.youtube.com/iframe_api";
        $(tag).load(loadData);
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        firstScriptTag.parentNode.insertBefore(youtubeTag, firstScriptTag);
        window.onYouTubePlayerAPIReady = function() {
            settings.youtubeReady = true;
            $el.player.each(function(index, element) {
                var $thumbs = $(element).closest('.video-gallery').find('.video-gallery__thumb'),
                    videoId = $thumbs.first().attr('data-youtubeid'),
                    newPlayer,
                    vids = [];
                if ($(window).width() > 767) {
                    $(element).closest('.video-gallery').find('.video-gallery__thumb-wrapper').height($(element).closest('.video-gallery__player-wrapper').outerHeight());
                } else {
                    $(element).closest('.video-gallery').find('.video-gallery__tabs').after($(element).closest('.video-gallery').find('.video-gallery__thumb-wrapper').detach());
                    $el.thumbs = $('.video-gallery__thumb');
                }
                $thumbs.first().addClass('active');
                $(element).attr('data-playernumber', index);
                $thumbs.slice(1).each(function(index, element) {
                    vids.push($(element).attr('data-youtubeid'));
                });
                if ($thumbs.first().find('.video-gallery__thumb-name h3 .scWebEditInput').length) {
                    title = $thumbs.first().find('.video-gallery__thumb-name h3 .scWebEditInput').text();
                } else {
                    title = $thumbs.first().find('.video-gallery__thumb-name h3').text();
                }
                $(element).closest('.video-gallery__player-container').find('.video-gallery__player-name h3').text(title);
                newPlayer = new YT.Player($(element)[0], {
                    videoId: videoId,
                    height: '900',
                    width: '1600',
                    playerVars: {
                        rel: 0,
                        showinfo: 1,
                        autohide: 2,
                        modestbranding: 1
                    },
                    events: {
                        'onStateChange': onPlayerStateChange,
                        'onReady': onPlayerReady
                    }
                });
                players[index] = newPlayer;
            });
        }
        bindEvents_vPlayer();
    }

    function updateThumbs() {
        $el.thumbs.each(function(index, element) {
            var mom = moment.duration(videoData[index].contentDetails.duration),
                $img = $(element).find('img'),
                dur = mom.minutes() + ':' + ((mom.seconds() < 10) ? '0' : '') + mom.seconds(),
                title;
            if ($(element).find('.video-gallery__thumb-name h3 .scWebEditInput').length) {
                title = $(element).find('.video-gallery__thumb-name h3 .scWebEditInput').text();
            } else {
                title = $(element).find('.video-gallery__thumb-name h3').text();
            }
            if (!$img.attr('src')) {
                $img.attr('src', videoData[index].snippet.thumbnails.medium.url);
            }
            $img.attr('data-largesrc', videoData[index].snippet.thumbnails.maxres ? videoData[index].snippet.thumbnails.maxres.url : videoData[index].snippet.thumbnails.high.url);
            $(element).find('.video-gallery__thumb-duration').text(dur);
            $(element).attr('aria-label', title + ', duration ' + dur);
            $(element).attr('data-label', title + ', duration ' + dur);
        });
    }

    function loadData() {
        $el.thumbs.each(function(index, element) {
            ids.push($(element).attr('data-youtubeid'));
        });
        $.ajax({
            type: 'GET',
            url: settings.dataurl,
            data: { part: 'snippet,contentDetails', id: ids.join(','), key: settings.youtubekey },
            dataType: 'json',
            error: function(data) {},
            success: function(data) {
                videoData = data.items;
                updateThumbs();
            }
        });
    }

    function fixLabels() {
        var $gallery,
            $img;
        $el.thumbs.each(function(index, element) {
            if ($(element).hasClass('active')) {
                $gallery = $(element).closest('.video-gallery');
                $img = $(element).find('img');
                $gallery.find('.video-gallery__player-play img').attr('src', $img.attr('data-largesrc'));
                $(element).attr('aria-label', 'Now Playing ' + $(element).attr('data-label'));
                if (!$('#' + $(element).attr('data-infotab')).hasClass('active')) {
                    $gallery.find('.video-gallery__video-data').removeClass('last');
                    $gallery.find('.video-gallery__video-data.active').removeClass('active').addClass('last');
                    $('#' + $(element).attr('data-infotab')).removeClass('last').addClass('active');
                }
            } else {
                $(element).attr('aria-label', $(element).attr('data-label'));
            }
        });
    }

    /// Setup Events
    function bindEvents_vPlayer() {
        if ($el.thumbs.length) {
            $el.thumbs.on("click",function(event) {
                var $player = $(this).closest('.video-gallery').find('.video-gallery__player'),
                    videoID = $(this).attr('data-youtubeid') || $('.video-gallery__thumb').first().attr('data-youtubeid'),
                    number = parseInt($player.attr('data-playernumber'), 10),
                    playList;
                players[number].loadVideoById(videoID);
                $el.gallery.find('.video-gallery__thumb').removeClass('active');
                $(this).addClass('active');
                $player.show();
                fixLabels();
                scrollTopThumb($el.gallery);
            });
        }
        if ($el.play.length) {
            $el.play.on("click",function() {
                var $player = $(this).closest('.video-gallery').find('.video-gallery__player'),
                    number = parseInt($player.attr('data-playernumber'), 10);
                players[number].playVideo();
            });
        }
    }



    function onPlayerStateChange(event) {
        var data,
            $gallery,
            $player,
            number,
            videoID,
            $galleryWrapper,
            title;

        if (event.data === 1 || event.data === 3) {
            $gallery = $(event.target.f).closest('.video-gallery');
            $gallery.find('.video-gallery__thumb').removeClass('active');
            data = event.target.getVideoData();

            $galleryWrapper = $gallery.find('.video-gallery__thumb[data-youtubeid="' + data.video_id + '"]');
            $galleryWrapper.addClass('active');

            if ($galleryWrapper.find('.video-gallery__thumb-name h3 .scWebEditInput').length) {
                title = $galleryWrapper.find('.video-gallery__thumb-name h3 .scWebEditInput').text();
            } else {
                title = $galleryWrapper.find('.video-gallery__thumb-name h3').text();
            }
            $gallery.find('.video-gallery__player-name h3').text(title);

            fixLabels();
            $gallery.find('.video-gallery__player-wrapper').addClass('playing');
            $player = $gallery.find('.video-gallery__player');
            number = parseInt($player.attr('data-playernumber'), 10);
        }
        if (event.data === 0) {
            $gallery = $(event.target.f).closest('.video-gallery');
            $player = $gallery.find('.video-gallery__player');
            number = parseInt($player.attr('data-playernumber'), 10);

            videoID = $gallery.find('.video-gallery__thumb.active').attr('data-youtubeid');
            $('.video-gallery__thumb.active').removeClass('active').next().addClass('active');
            var index = ids.indexOf(videoID) + 1;
            if (index >= ids.length) {
                index = 0;
                return;
                //uncomment line below to do an infinite video loop
                //$('.video-gallery__thumb').removeClass('active').first().addClass('active');
            }
            players[number].loadVideoById(ids[index]);
            scrollTopThumb($gallery);
        }

    }

    function scrollTopThumb(galleryWrapper) {
        var $thumbWrapper = $(galleryWrapper).find('.video-gallery__thumb-wrapper'),
            $activeThumb = $(galleryWrapper).find('.video-gallery__thumb.active');
        $thumbWrapper.scrollTop($thumbWrapper.scrollTop() + $activeThumb.position().top);
    }

    function onPlayerReady(event) {
        event.target.playVideo();
        $('.video-gallery__player-wrapper iframe').show();
    }

    /**
     *   Public Methods
     **/

    videoGallery.init = function() {
        if ($el.gallery.length > 0) {
            setTimeout(() => {
                setup();
            }, 5000);

        }
    };
})(
    window.BDM = window.BDM || {},
    window.BDM.videoGallery = window.BDM.videoGallery || {}
);

//load when ready
$(function() {
    window.BDM.videoGallery.init();
});