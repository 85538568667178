/*globals $*/
(function (BDM, Core) {
  'use strict';

  //NEW TABS MODULE
  if ($('#sbs-tabs').length > 0) {
    $('#tabs-wrapper .item').on('click', function () {
      var target = $(this).data('target');
      $(this).addClass('active').siblings().removeClass('active');
      $('#content-' + target)
        .addClass('active')
        .siblings()
        .removeClass('active');
    });
  }

  var settings = {
    mapskey: 'AIzaSyBktpiCTvZ9v-esDkgKGwQyarq72Kdptno',
    youtubekey: 'AIzaSyA1Lwn0iXerbTk37RncVvxEKG5ArbIgA48',
    reverseURL: 'https://maps.googleapis.com/maps/api/geocode/json',
  },
    $el = {
      //stored elements
      html: $('html'),
      main: $('main'),
      skip: $('.skip'),
      select: $('.degree-finder__filter select'),
      requestSelect: $('.overlay select'),
      formSelect: $('section form select'),
      formArrows: $('.form-arrows'),
      readmore: $(
        '.readmore .readmore__button, .readmore .readmore__link, .readmore .readmore__link--misc'
      ),
      tabsbar: $('.tabs__wrapper .nav.nav-tabs'),
      pillsScrollable: $('.nav-pills__wrapper--scrollable'),
      tabs: $('.nav.nav-tabs > li'),
      pills: $('.nav.nav-pills > li'),
      pillsDegreeFinder: $('.degree-finder .nav-pills a'),
      globalNav: $('.nav__global'),
      globalHeader: $('.header__global'),
      parnerHeader: $('.parner_header'),
      request: $('.nav__request-info'),
      navs: $('.nav__global li > a, .nav__global li > button'),
      mobileNavButton: $('.nav__mobilebutton'),
      subnavs: $('.nav__global li.nav__link'),
      subnavTile: $('.nav__global li.nav__link .nav__subnav-mobiletitle'),
      navSearch: $('.nav__search-button'),
      navSearchClose: $('.nav__search-close'),
      navSearchField: $('input[name="searchText"]'),
      accordion: $('.accordion'),
      accordionV2: $('.accordionV2'),
      youtube: $('[data-videoid]'),
      overlay: $('.overlay'),
      mainOverlayModal: $('main > .overlay'),
      overlayModalClose: $('.overlay .overlay__close'),
      overlayModalBack: $('.overlay .overlay__content-wrapper'),
      videoModal: $('.overlay__video-wrapper'),
      locationZipcode: $('.location__zipcode'),
      locationZipcodeReader: $('.location__zipcode--reader'),
      locationEdit: $('.location__edit-window'),
      locationField: $('.location__field, [name="zipcode"]'),
      waypoint: $('.waypoint'),
      section: $('section'),
      header: $('.header__global'),
      hero: $('.hero'),
      heroVideo: $('.hero__background-video'),
      sidebyside: $('.sidebyside'),
      partner_hero: $('.partner_hero'),
      benefits: $('.benefits'),
      horizontal_form: $('.horizontal_form'),
      relatedProgramCards: $('.card__program .card__program-content'),
      relatedEventsCards: $('.card__event .card__event-content'),
      reqOptions: $('.admission-req__option-text'),
      reqAditional: $('.admission-req__additional-text'),
      bubbles: $('.bubbles'),
      faculty: $('.faculty__container'),
      leftNavLinks: $('.leftnav li a'),
      schoolsWrapper: $('.schools__wrapper'),
      schools: $('.schools__school'),
      formsLinks: $('.formslinks'),
      readonly: $('input[readonly]'),
      lists: $(
        '.section__content ul, .section__description ul, .section__text ul, .admission-req__additional-container ul, .admission-req__option-container ul'
      ),
      zipfield: $('input[name="zipcode"]'),
      locationsPopupLink: $('.program-detail__locations-link'),
      locationsPopup: $('.program-detail .overlay'),
      locationsLinks: $('.overlay__locations .program-detail__location'),
      responsiveTable: $('.table-responsive'),
      print: $('.print__button'),
      stats: $('.stats__fact'),
      mobileTopNav: $(
        '.nav__topnav .nav__topnav-request, .nav__topnav .phone-btn a, .nav__topnav .chat__link a'
      ),
      backButton: $('.back-button'),
      leave: $('.btn-link-leave, a[target="_blank"]'),
      pagination: $('.pagination'),
      altCTA: $('.hero__altcta'),
      searchResults: $('.search-results__description, .search-results__name'),
      internationalZip: $('input[name="student-type"]'),
    },
    player = [],
    headerWaypoint,
    waypoints,
    videoWaypoint,
    heroPlayer,
    lastFocus,
    scrollPos,
    navScrollPos,
    focusElements = 'select, input, textarea, button, a',
    zipcode,
    userAgent = navigator.userAgent.toLowerCase();
  /**
   *   Helper Methods
   **/

  /*LEFT NAV*/

  if ($('.leftnav__subnav').length) {
    $('.leftnav__subnav').show();
  }

  /*LEFT NAV*/

  //AddEvent Settings
  window.addeventasync = function () {
    addeventatc.settings({
      license: 'atHhmszCEzJafqecWmwH30254',
      mouse: true,
      css: false,
      outlook: { show: true, text: 'Outlook' },
      google: { show: true, text: 'Google <em>(online)</em>' },
      yahoo: { show: true, text: 'Yahoo <em>(online)</em>' },
      outlookcom: { show: true, text: 'Outlook.com <em>(online)</em>' },
      appleical: { show: true, text: 'Apple Calendar' },
      facebook: { show: true, text: 'Facebook Event' },
      dropdown: { order: 'outlook,google,appleical,yahoo,outlookcom' },
    });
  };
  window.addeventasync = function () {
    window.BDM.Core.addATCEvents();
  };
  //End AddEvent Settings

  //recaptcha
  function getCaptchaResponse(token) {
    $.ajax({
      async: false,
      type: 'POST',
      url: '/api/forms/ValidateCaptcha',
      data: "{token: '" + token + "'}",
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success: function (response) {
        if (response.RecaptchaResult) {
          $('.join-now-recaptcha').submit();
        }
      },
    });
  }

  function validateRecaptcha() {
    var sitekey = $('input[name="siteKey"]').data('key');
    grecaptcha.ready(function () {
      grecaptcha
        .execute(sitekey, { action: 'WFFMJoinNow' })
        .then(function (token) {
          getCaptchaResponse(token);
        });
    });
  }

  var captchaButton = $('.btn-recaptcha');
  if (captchaButton) {
    $('.btn-recaptcha').on('click', function (e) {
      e.preventDefault();
      validateRecaptcha();
    });
  }
  //recaptcha

  //Detect if there is breadcrumb

  if (
    $('.breadcrumb__nav').length === 0 &&
    $('.hero__home').length === 0 &&
    $('.nav__topnav-wrapper').length === 0
  ) {
    $('main').addClass('no-bread');
  } else if (
    $('.nav__topnav-wrapper.hidden-lg.hidden-md.hidden-sm').length > 0
  ) {
    $('main').addClass('no-bread');
  }

  //forms

  //Annoucements bar
  $('#annBar #close').on('click', function () {
    $('#annBar').remove();
  });

  if ($('#annBar').length > 0) {
    var timeOut = $('#annBar').data('timeout');
    setTimeout(function () {
      $('#annBar').remove();
    }, timeOut);

  }

  //Finish Annoucement bar


  //forms

  /*FINISH MOVE JS FROM VIEW TO MINIFY*/

  function addToCalendar() {
    if (window.addtocalendar)
      if (typeof window.addtocalendar.start == 'function') return;
    if (window.ifaddtocalendar == undefined) {
      window.ifaddtocalendar = 1;
      var d = document,
        s = d.createElement('script'),
        g = 'getElementsByTagName';
      s.type = 'text/javascript';
      s.charset = 'UTF-8';
      s.async = true;
      s.src =
        ('https:' == window.location.protocol ? 'https' : 'http') +
        '://addtocalendar.com/atc/1.5/atc.min.js';
      var h = d[g]('body')[0];
      h.appendChild(s);
    }
  }

  function setup() {
    
    //LOAD NATIVELY HTML5 YOUTUBE VIDEOS

    function openLightbox(element, videoId) {
      event.preventDefault();
      var lightbox = document.createElement('div');
      lightbox.classList.add('newLightbox');
      lightbox.innerHTML = `
        <div class="newLightbox-content">
          <div class="lightbox-close">X</div>
          <iframe src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      `;
      document.body.appendChild(lightbox);
      lightbox.querySelector('.lightbox-close').addEventListener('click', function() {
        lightbox.remove();
      });
      lightbox.addEventListener('click', function(event) {
          if (event.target === lightbox) {
              lightbox.remove();
          }
      });
    }

    window.openLightbox = openLightbox;

    let programs = window.sessionStorage.getItem('programs');
    if (!programs) {
      $.ajax({
        url: '/api/Sitecore/Forms/GetProgramListingV4',
        type: 'GET',
        context: this,
        success: function (data) {
          if (data.data?.ProgramList) {
            const jsonObject = JSON.stringify(data.data.ProgramList);
            window.sessionStorage.setItem('programs', jsonObject);
          } else {
            window.sessionStorage.setItem('programs', '[]');
          }
        },
        error: function (data) {
        },
      });
    }

    if ($el.html.length) {
      $($el.html).scroll(function () {
      });
    }

    addToCalendar();
    if ($('img[alt=""]').length) {
      $('img[alt=""]').attr('aria-hidden', true);
    }
    if ($el.altCTA.length) {
      $el.altCTA.each(function (index, element) {
        $(element)
          .find('a')
          .attr(
            'aria-label',
            $(element).find('a').text() + ': ' + $(element).find('p').text()
          );
      });
    }

    if ($el.searchResults.length) {
      var query = $('#site-search').val().split(' ');
      if (query.length) {
        $el.searchResults.each(function (index, element) {
          query.forEach(function (word) {
            if (word) {
              $(element).html(
                $(element)
                  .html()
                  .replace(
                    new RegExp('\\b' + word + '\\b', 'gi'),
                    '<span class="highlight">$&</span>'
                  )
              );
            }
          });
        });
      }
    }
    if ($el.stats.length) {
      $el.stats.each(function (index, element) {
        var value = $(element).find('.stats__value').attr('data-value'),
          label = $(element).find('.stats__label').text(),
          desc = $(element).find('.stats__description').text();
        $(element).attr('aria-label', value + label + ' ' + desc);
      });
    }
    if ($el.tabs.length) {
      $('.tabs__content').find(focusElements).hide();

      $('.tabs__content').filter('.active').find(focusElements).show();
      $el.tabs
        .closest('.tabs__wrapper')
        .append(
          '<span class="icon icon__rightmorearrow" aria-hidden="true"></span>'
        );
    }
    if ($el.formArrows.length) {
      $el.formArrows.each(function (index, element) {
        $(element)
          .find('li a')
          .eq(1)
          .attr('aria-label', 'Complete form before continuing');
      });
    }
    if ($el.pillsScrollable.length) {
      $el.pillsScrollable.each(function (index, element) {
        $(element).append(
          '<span class="icon icon__rightmorearrow" aria-hidden="true"></span>'
        );
      });
    }
    if ($el.pills.length) {
      $el.pills.filter(':first-child').each(function (index, element) {
        if (
          $(element).closest('.search-results__filter').length === 0 &&
          $(element).closest('.nav-pills').find('.active').length === 0
        ) {
          $(element).addClass('active');
          $(element)
            .find('a')
            .attr('aria-label', $(element).find('a').text() + ' selected');
          $(element)
            .closest('.tabs__wrapper')
            .find('.tabs__content')
            .first()
            .addClass('active')
            .attr('aria-hidden', 'false');
          $(
            '#' +
            $(element)
              .closest('.tabs__wrapper')
              .find('.tabs__content')
              .first()
              .attr('aria-labelledby')
          ).attr('aria-selected', 'true');
        }
      });
    }
    if ($el.waypoint.length) {
      var observerOptions = {
        root: null,
        threshold: 0.5 // Equivalente a offset: '50%'
      };

      var observerCallback = function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var element = entry.target;

            if (!element.classList.contains('career-impact')) {
              element.classList.add('onscreen');
            } else {
              element.classList.add('reached');
            }

            if (element.classList.contains('career-impact') && element.classList.contains('ready')) {
              element.classList.add('onscreen');
              element.querySelectorAll('.career-impact__jobs-total').forEach(function(el) {
                el.querySelector('span').textContent = el.getAttribute('data-value');
              });
              element.querySelector('.career-impact__jobs-outer').classList.add('animate');
            }

            if (element.classList.contains('stats')) {
              element.querySelectorAll('.stats__value').forEach(function(el) {
                el.querySelector('.odometer').innerHTML = el.getAttribute('data-value');
              });
            }
          }
        });
      };

      var observer = new IntersectionObserver(observerCallback, observerOptions);

      $el.waypoint.each(function(index, el) {
        observer.observe(el);
      });
    }

    if ($el.section.length && $el.header.length) {
      var headerObserverOptions = {
          root: null,
          threshold: 0
      };

      var headerObserverCallback = function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            $el.header.toggleClass('sticky', entry.boundingClientRect.top < 0);
          }
        });
      };

      var headerObserver = new IntersectionObserver(headerObserverCallback, headerObserverOptions);

      if ($el.header.hasClass('header__landing')) {
        headerObserver.observe($el.section.eq(1)[0]);
      } else {
        headerObserver.observe($el.section.first()[0]);
      }
    }

    if ($el.accordion.length) {
      $el.accordion.find(' > li').first().addClass('open');
      $el.accordion
        .find(' > li')
        .first()
        .find('button')
        .attr('aria-expanded', true);
      $el.accordion
        .find(' > li')
        .first()
        .find('.accordion__section-wrapper')
        .attr('hidden', false);
    }
    if ($el.formsLinks.length) {
      $el.formsLinks.each(function (index, element) {
        $(element)
          .find('.tabs__content select')
          .each(function (subindex, subelement) {
            var $tab = $(subelement).closest('.tabs__content'),
              tabname = $tab.attr('data-tab');
            $tab
              .find('#' + tabname + '-year-' + $(subelement).val())
              .addClass('active');
          });
      });
    }
    if ($el.readonly.length) {
      $el.readonly.each(function (index, element) {
        $(element).attr('size', $(element).val().length);
        $(element).hide();
        setTimeout(function () {
          $(element).show();
        }, 100);
      });
    }
    if ($el.lists.length) {
      $el.lists.each(function (index, element) {
        if (!$(element).hasClass('nav')) {
          if ($(element).hasClass('checks')) {
            if (!$(element).find('span.icon__checkoutline').length) {
              $(element)
                .find('li')
                .prepend(
                  '<span class="list__icon icon icon__checkoutline" aria-hidden="true"></span>'
                );
            }
          }
        }
      });
    }
    if ($el.readmore.length) {
      $el.readmore.each(function (index, element) {
        var $this = $(element),
          $parent = $this.parent('.readmore'),
          title;
        if ($this.hasClass('readmore__link--misc')) {
          title = $this.find('.readmore__button-text').text();
          $this.find('.readmore__button-text').attr('data-title', title);
          $this.find('button').attr('aria-label', 'Show ' + title);
        } else if ($this.hasClass('readmore__link')) {
          title = $this.parents('.datablock').find('.datablock__title').text();
          $this.find('.readmore__button-text').text('See Description');
          $this.find('button').attr('aria-label', 'See more about ' + title);
        } else {
          title = $this.closest('.container').find('.section__header *').text();
          $this.find('.readmore__button-text').text('Read More');
          $this.find('button').attr('aria-label', 'Read more about ' + title);
        }
      });
    }
    if ($el.subnavTile.length) {
      if ($(window).width() < 1200) {
        $el.subnavTile.attr('aria-hidden', false);
      }
    }
  }

  function resizeHandler(event) {
    if ($el.hero.length) {
      var mh = 325;
      if ($(window).width() <= 1024) {
        mh = 150;
      }
    }
    if ($('form textarea').length && !window.matchMedia("only screen and (max-width: 768px)").matches) {
      $('form textarea').each(function (index, element) {
        $(element).jqresizable({
          handles: 'se',
          minHeight: 96,
          grid: [100000000, 1],
        });
      });
    }

    if ($el.relatedProgramCards.length && $(window).width() > 767) {
      equalheight($el.relatedProgramCards);
    } else {
      $el.relatedProgramCards.css('height', '');
    }
    if ($el.locationsLinks.length && $(window).width() > 767) {
      equalheight($el.locationsLinks);
    } else {
      $el.locationsLinks.css('height', '');
    }
    if ($el.relatedEventsCards.length && $(window).width() > 767) {
      equalheight($el.relatedEventsCards);
    } else {
      $el.relatedEventsCards.css('height', '');
    }
    if ($el.reqOptions.length && $(window).width() > 767) {
      equalheight($el.reqOptions);
    } else {
      $el.reqOptions.css('height', '');
    }
    if ($el.reqAditional.length && $(window).width() > 767) {
      equalheight($el.reqAditional);
    } else {
      $el.reqAditional.css('height', '');
    }
    if ($el.bubbles.length && $(window).width() > 767) {
      $el.bubbles.each(function (index, element) {
        equalheight($(element).find('.bubbles__image'));
        equalheight($(element).find('.bubbles__header'));
      });
    } else {
      $el.bubbles.each(function (index, element) {
        $(element).find('.bubbles__image').css('height', '');
        $(element).find('.bubbles__header').css('height', '');
      });
    }
    if ($el.tabsbar.length) {
      $el.tabsbar.each(function (index, element) {
        if ($(element).find('li').length <= 1) {
          $(element).closest('.tabs__wrapper').addClass('single-tab');
        }
        if (!$(element).hasClass('form-arrows')) {
          $(element)
            .closest('.tabs__wrapper')
            .toggleClass(
              'scrollable',
              element.scrollWidth > element.clientWidth
            );
        }
        $(element).attr(
          'data-max',
          Math.abs(element.scrollWidth - element.clientWidth)
        );
      });
    }
    if ($el.pillsScrollable.length) {
      $el.pillsScrollable.each(function (index, element) {
        $(element).toggleClass(
          'scrollable',
          element.scrollWidth > element.clientWidth
        );
        $(element)
          .find('.nav.nav-pills')
          .attr(
            'data-max',
            Math.abs(
              $(element).find('.nav.nav-pills')[0].scrollWidth -
              $(element).find('.nav.nav-pills')[0].clientWidth
            )
          );
      });
    }
    if ($el.responsiveTable.length) {
      $el.responsiveTable.each(function (index, element) {
        $(element)
          .closest('.responsivetable')
          .toggleClass(
            'scrollable',
            $(element)[0].scrollWidth > $(element)[0].clientWidth
          );
      });
    }
    if ($('.anchors__column').length && $(window).width() > 767) {
      equalheight($('.anchors__column'));
    } else {
      $('.anchors__column').css('height', '');
    }
    if ($el.pagination.length) {
      var $pagination = $('.pagination'),
      windowWidth = $(window).width(),
      limits = { mobile: 5, tablet: 10, desktop: 15 },
      limit = windowWidth < 480 ? limits.mobile : windowWidth < 768 ? limits.tablet : limits.desktop,
      totalPages = $pagination.find('li').not('.previous,.next').length,
      currentPage = Math.max($pagination.find('li.active').index(), 1),
      startPage = Math.max(2, Math.min(currentPage - Math.floor((limit - 3) / 2), totalPages - limit + 3)),
      endPage = Math.min(totalPages - 1, startPage + limit - 5);

      $pagination.find('li').hide().removeClass('dynamic-ellipsis');
      $pagination.find('li:first-child, li:last-child').show();
      $pagination.find('li:nth-child(2), li:nth-last-child(2)').show();
      $pagination.find('li').slice(startPage - 1, endPage + 1).show();

      if (startPage > 2) $('<li class="dynamic-ellipsis"><span class="page-link">...</span></li>').insertAfter($pagination.find('li:nth-child(2)')).show();
      if (endPage < totalPages - 1) $('<li class="dynamic-ellipsis"><span class="page-link">...</span></li>').insertBefore($pagination.find('li:nth-last-child(2)')).show();
    }
    if ($el.stats.length) {
      $el.stats.each(function (index, element) {
        $(element)
          .find('.stats__value')
          .css(
            'padding-right',
            $(element).find('.stats__label').innerWidth() + 2 + 'px'
          );
      });
    }
  }

  function closeModal() {
    var playerNumber;
    if ($el.mainOverlayModal.hasClass('overlay__video-active')) {
      $el.mainOverlayModal.removeClass('overlay__video-active');

      playerNumber = $('.overlay__video-container.active').attr('data-player');
      player[playerNumber].stopVideo();
      $('.overlay__video-container').removeClass('active');
    }
    if ($el.mainOverlayModal.hasClass('overlay__form-active')) {
      $el.mainOverlayModal.removeClass('overlay__form-active');
    }
    $('.overlay').removeClass('open');
    $el.html.removeClass('modal--open');
    lastFocus.focus();
  }

  /// Setup Events
  function bindEvents() {
    if ($('input').length && userAgent.indexOf('android') > -1) {
      $('input')
        .on('focus', function (event) {
          $('.body').addClass('keyboard');
        })
        .on('blur', function () {
          $('.body').removeClass('keyboard');
        });
    }
    if ($el.select.length && window.matchMedia("only screen and (max-width: 768px)").matches) {
      $el.select.each(function (index, element) {
        var val = $(element).val();
        $(element).find('optgroup option').unwrap();
        $(element).val(val).change();
      });
    }
    if ($el.formSelect.length && window.matchMedia("only screen and (max-width: 768px)").matches) {
      $el.formSelect.find('optgroup option').unwrap();
    }
    if ($el.requestSelect.length && window.matchMedia("only screen and (max-width: 768px)").matches) {
      $el.requestSelect.find('optgroup option').unwrap();
    }
    if ($el.skip.length) {
      $el.skip.on('click', function (e) {
        e.preventDefault();
        $('main').find(focusElements).first().focus();
      });
    }
    if ($el.tabs.length) {
      $el.tabs.on('click', function (event) {
        var $this = $(this),
          $parent = $this.parent('.nav'),
          $tabcontent = $this.closest('.tabs__wrapper').find('.tabs__content');
        event.preventDefault();
        if ($parent.hasClass('form-arrows')) {
          if (
            !$this
              .closest('.tabs__wrapper')
              .find('.tabs__content.active')
              .hasClass('complete')
          ) {
            return;
          } else {
            $this.find('a').attr('aria-label', '');
          }
        }
        $parent.find('li a').removeClass('active');
        $parent.find('li a').attr('aria-selected', 'false');
        $this.find('a').addClass('active');
        $this.find('a').attr('aria-selected', 'true');
        if ($tabcontent.length) {
          $tabcontent.removeClass('active').attr('aria-hidden', 'true');
          $tabcontent
            .filter('[data-index="' + $this.attr('data-index') + '"]')
            .addClass('active')
            .attr('aria-hidden', 'false');
          $tabcontent.find(focusElements).hide();
          $tabcontent
            .filter('[data-index="' + $this.attr('data-index') + '"]')
            .find(focusElements)
            .show();
          resizeHandler();
        }
      });
      $el.tabsbar.each(function (index, element) {
        var margin, max;
        new Hammer(element, { domEvents: true });
        $(element).on('panstart', function (e) {
          $(element).attr(
            'data-max',
            Math.abs(element.scrollWidth - element.clientWidth)
          );
          margin = parseInt($(element).css('margin-left'), 10);
        });
        $(element).on('pan', function (e) {
          var delta = margin + e.originalEvent.gesture.deltaX;
          max = parseInt($(element).attr('data-max'), 10);
          if (delta >= -max && delta <= 0) {
            $(element).css({
              'margin-left': margin + e.originalEvent.gesture.deltaX,
            });
          }
        });
      });
    }
    if ($el.pillsScrollable.length) {
      $el.pillsScrollable
        .find('.nav.nav-pills')
        .each(function (index, element) {
          var margin, max;
          new Hammer(element, { domEvents: true });
          $(element).on('panstart', function (e) {
            $(element).attr(
              'data-max',
              Math.abs(element.scrollWidth - element.clientWidth)
            );
            margin = parseInt($(element).css('margin-left'), 10);
          });
          $(element).on('pan', function (e) {
            var delta = margin + e.originalEvent.gesture.deltaX;
            max = parseInt($(element).attr('data-max'), 10);
            if (delta >= -max && delta <= 0) {
              $(element).css({
                'margin-left': margin + e.originalEvent.gesture.deltaX,
              });
            }
          });
        });
    }
    if ($el.pills.length) {
      $el.pills.on('click', function (event) {
        var $this = $(this),
          $parent = $this.parent('.nav'),
          $tabcontent = $this.closest('.tabs__wrapper').find('.tabs__content'),
          $searchResults = $(this).closest('.search-results__filters');
        if ($searchResults.length === 0) {
          event.preventDefault();
          $parent.find('li').removeClass('active');
          $parent.find('li a').each(function (index, element) {
            $(element).attr('aria-label', $(element).text());
          });
          $this.addClass('active');
          $this
            .find('a')
            .attr('aria-label', $this.find('a').text() + ' selected');
          if ($tabcontent.length) {
            $tabcontent.removeClass('active').attr('aria-hidden', 'true');
            $tabcontent
              .filter('[data-index="' + $this.attr('data-index') + '"]')
              .addClass('active')
              .attr('aria-hidden', 'false');
            resizeHandler();
          }
        }
      });
    }
    if ($el.pillsDegreeFinder.length) {
      $el.pillsDegreeFinder.on('click', function (event) {
        var $this = $(this),
        $parent = $this.parent().parent('.nav');
        event.preventDefault();
        $parent.find('a').removeClass('text-default');
        $parent.find('a').removeClass('bg-dark');
        $this.addClass('text-default bg-dark');
      });
    }
    if ($el.readmore.length) {
      $el.readmore.on('click', function (event) {
        var $this = $(this),
          $parent = $this.parent('.readmore'),
          title;
        if ($this.parents('.datablock').length) {
          title = $this.parents('.datablock').find('.datablock__title').text();
        } else {
          title = $this.closest('.container').find('.section__header *').text();
        }
        event.preventDefault();
        if ($parent.hasClass('readmore__open')) {
          $this.find('button').attr('aria-expanded', false);
          if ($this.hasClass('readmore__link--misc')) {
            title = $this.find('.readmore__button-text').attr('data-title');
            $this.find('button').attr('aria-label', 'Show ' + title);
            $this.find('.readmore__button-text').text(title);
          } else if ($this.hasClass('readmore__link')) {
            $this.find('.readmore__button-text').text('See Description');
            $this.find('button').attr('aria-label', 'See more about ' + title);
          } else {
            $this.find('.readmore__button-text').text('Read More');
            $this.find('button').attr('aria-label', 'Read more about ' + title);
          }
        } else {
          $this.find('button').attr('aria-expanded', true);
          if ($this.hasClass('readmore__link--misc')) {
            title = $this.find('.readmore__button-text').attr('data-title');
            $this.find('button').attr('aria-label', 'Hide ' + title);
            $this.find('.readmore__button-text').text(title);
          } else if ($this.hasClass('readmore__link')) {
            $this.find('.readmore__button-text').text('Hide Description');
            $this.find('button').attr('aria-label', 'Hide more about ' + title);
          } else {
            $this.find('.readmore__button-text').text('Read Less');
            $this.find('button').attr('aria-label', 'Read less about ' + title);
          }
        }
        $parent.toggleClass('readmore__open');
      });
    }
    if ($el.navs.length) {
      $el.navs.on('click', function (event) {
        var $this = $(this),
          $parent = $this.closest('li.nav__link');
        if ($this.hasClass('nav__dropdown-toggle')) {
          event.preventDefault();
          if ($parent.hasClass('open')) {
            $el.navs.attr('aria-expanded', false);
            $el.subnavs.removeClass('open');
          } else {
            $el.subnavs.removeClass('open');
            $this.attr('aria-expanded', true);
            $parent.addClass('open');
            $el.globalNav.addClass('nav__subnav--open');
            if ($(window).width() < 1200) {
              $parent.find('.nav__subnav-mobiletitle').attr('tabindex', 0);
              $parent.find('.nav__subnav-mobiletitle')[0].focus();
            }
          }
        }
      });
      $el.navs.keydown(function (event) {
        if (
          event.keyCode === 32 ||
          event.keyCode === 13 ||
          event.keyCode === 40
        ) {
          var $this = $(this),
            $parent = $this.closest('li.nav__link');
          if ($this.hasClass('nav__dropdown-toggle')) {
            event.preventDefault();
            if ($parent.hasClass('open')) {
              if (event.keyCode === 40) {
                $parent.find('.nav__subnav a').first().focus();
              } else {
                $el.navs.attr('aria-expanded', false);
                $el.subnavs.removeClass('open');
              }
            } else {
              $el.subnavs.removeClass('open');
              $this.attr('aria-expanded', true);
              $parent.addClass('open');
              $el.globalNav.addClass('nav__subnav--open');
            }
          } else if (event.keyCode === 40) {
            event.preventDefault();
          }
        }
      });
    }
    if ($el.mobileNavButton.length) {
      $el.mobileNavButton.on('click', function (event) {
        var $this = $(this);
        event.preventDefault();
        if ($el.globalNav.hasClass('open')) {
          $el.globalHeader.removeClass('open');
          $this.removeClass('open');
          $this.attr('aria-expanded', false);
          $el.navs.attr('aria-expanded', false);
          $el.globalNav.removeClass('nav__subnav--open open');
          $el.subnavs.removeClass('open');
          $el.html.removeClass('modal--open');
          $('.main').css({ top: '', position: '' });
          $('body').scrollTop(navScrollPos);
        } else {
          $el.globalHeader.addClass('open');
          $this.addClass('open');
          $el.globalNav.addClass('open');
          $this.attr('aria-expanded', true);
          navScrollPos = $('body').scrollTop();
          $('.main').css({
            top: -navScrollPos - $el.globalHeader.height() + 'px',
            position: 'relative',
          });
          $('body').scrollTop(0);
          $el.html.addClass('modal--open');
        }
      });
    }
    if ($el.subnavTile.length) {
      $el.subnavTile.on('click', function (event) {
        event.preventDefault();
        $el.globalNav.removeClass('nav__subnav--open');
        $el.subnavs.removeClass('open');
        $el.navs.attr('aria-expanded', false);
        if ($(window).width() < 1200) {
          $(this)
            .closest('.nav__link')
            .find('.nav__dropdown-toggle')
            .attr('tabindex', 0);
          $(this)
            .closest('.nav__link')
            .find('.nav__dropdown-toggle')[0]
            .focus();
        }
      });
    }
    if ($el.main.length) {
      $el.main.on('click', function (event) {
        $el.globalNav.removeClass('nav__subnav--open');
        $el.subnavs.removeClass('open');
        $el.globalNav.removeClass('nav__search--open');

        if ($el.globalNav.hasClass('open')) {
          $el.globalHeader.removeClass('open');
          $el.mobileNavButton.removeClass('open');
          $el.mobileNavButton.attr('aria-expanded', false);
          $el.navs.attr('aria-expanded', false);
          $el.globalNav.removeClass('nav__subnav--open open');
          $el.subnavs.removeClass('open');
          $el.html.removeClass('modal--open');
          $('.main').css({ top: '', position: '' });
          $('body').scrollTop(navScrollPos);
        }
      });
    }
    if ($el.navSearch.length) {
      $el.navSearch.on('click', function (event) {
        event.preventDefault();
        $el.globalNav.removeClass('nav__subnav--open open');
        $el.subnavs.removeClass('open');
        $el.globalNav.addClass('nav__search--open');
        $el.navSearchField.focus();
        $el.navs.attr('tabindex', -1);
        $el.navSearch.attr('tabindex', -1);
        $('.desktopSearch').focus();
      });
    }
    if ($el.navSearchClose.length) {
      $el.navSearchClose.on('click', function (event) {
        event.preventDefault();
        $el.globalNav.removeClass('nav__subnav--open open');
        $el.subnavs.removeClass('open');
        $el.globalNav.removeClass('nav__search--open');
        $el.navSearch.focus();
        $el.navs.attr('tabindex', 0);
        $el.navSearch.attr('tabindex', 0);
      });
    }
    if ($el.accordionV2.length) {
      $el.accordionV2
        .find(' > li')
        .eq(0)
        .addClass('open')
        .find('.accordionV2__section-wrapper')
        .slideDown();
      $el.accordionV2.find(' > li').on('click', function (event) {
        var $this = $(this),
          $parent = $this.closest('.accordionV2');
        if ($this.hasClass('open')) {
          $this.removeClass('open');
          $this.find('.accordionV2__section-wrapper').slideUp();
        } else {
          //accordion
          $('.accordionV2__section').removeClass('open');
          $('.accordionV2__section-wrapper').slideUp();
          $this.addClass('open');
          $this.find('.accordionV2__section-wrapper').slideDown();
        }
      });
      $el.accordionV2.find(' > li a').on('click', function (event) {
        event.stopPropagation();
      });
    }
    if ($el.overlayModalClose.length) {
      $el.overlayModalClose.on('click', function (event) {
        event.preventDefault();
        closeModal();
      });
    }
    if ($el.overlayModalBack.length) {
      $el.overlayModalBack.on('click', function (event) {
        if (
          !$(this).hasClass('overlay__content') &&
          $(event.target).closest('.overlay__content').length === 0
        ) {
          event.preventDefault();
          closeModal();
        }
      });
    }
    if ($el.request.length) {
      $el.request.on('click', function (event) {
        var $this = $(this),
          inputs = $el.mainOverlayModal
            .find('select, input, textarea, button, a')
            .filter(':visible'),
          firstInput = inputs.first();
        event.preventDefault();
        lastFocus = document.activeElement;
        scrollPos = $('body').scrollTop();
        $('.body').css('top', -scrollPos + 'px');
        $el.html.addClass('modal--open');
        $el.mainOverlayModal.addClass('overlay__form-active open');
        firstInput.focus();
      });
    }
    if ($el.locationEdit.length) {
      $el.locationEdit.on('click', function (event) {
        event.preventDefault();
        var $window = $(this).closest('.location__edit-window');
        if ($window.hasClass('active')) {
          if (
            window.BDM.forms.validateField($window.find('.location__field'))
          ) {
            otherzipcode = $window.find('.location__field').val();
            $el.locationZipcode.text(otherzipcode);
            $el.locationZipcodeReader.text(otherzipcode.split('').join(' '));
            $el.locationField.val(otherzipcode).change();
            $window.removeClass('active');
          }
        } else {
          $window.addClass('active');
          $window.find('input').focus();
        }
      });
      $el.locationField.each(function (index, element) {
        if ($(element).val()) {
          $(element)
            .closest('section')
            .find('.location__zipcode')
            .text($(element).val());
          $(element)
            .closest('section')
            .find('.location__zipcode--reader')
            .text($(element).val().split('').join(' '));
        }
      });
    }
    if ($el.locationField.length) {
      $el.locationField.keydown(function (event) {
        if (event.keyCode === 13) {
          var $field = $(event.target);
          if (window.BDM.forms.validateField($field)) {
            otherzipcode = $field.val();
            $el.locationZipcode.text(otherzipcode);
            $el.locationZipcodeReader.text(otherzipcode.split('').join(' '));
            $el.locationField.val(otherzipcode).change();
            $field.closest('.location__edit-window').removeClass('active');
          }
        }
      });
      window.BDM.forms.bindValidation($el.locationField);
    }
    if ($el.zipfield.length) {
      $el.zipfield.keydown(window.BDM.forms.allowNumbers);
      $el.zipfield.keyup(function (event) {
        if (!$(this).attr('data-allownumbers')) {
          this.value = this.value.replace(/[^0-9]+/g, '');
        }
      });
    }
    if ($el.locationsPopupLink.length) {
      $el.locationsPopupLink.on('click', function (event) {
        var $this = $(this),
          inputs = $el.locationsPopup
            .find('select, input, textarea, button, a')
            .filter(':visible'),
          firstInput = inputs.first();
        event.preventDefault();
        lastFocus = document.activeElement;
        scrollPos = $('body').scrollTop();
        $('.body').css('top', -scrollPos + 'px');
        $el.html.addClass('modal--open');
        $el.locationsPopup.addClass('open');
        firstInput.focus();
      });
    }
    if ($el.formsLinks.length) {
      $el.formsLinks.find('select').on('change', function (event) {
        var $tab = $(event.target).closest('.tabs__content'),
          tabname = $tab.attr('data-tab');
        $tab.find('.formslinks__year-container').removeClass('active');
        $tab
          .find('#' + tabname + '-year-' + $(event.target).val())
          .addClass('active');
      });
    }
    if ($el.print.length) {
      $el.print.on('click', function (event) {
        window.print();
      });
    }
    if ($el.overlay.length) {
      $el.overlay.each(function (index, element) {
        var inputs = $(element).find(focusElements),
          firstInput = inputs.first(),
          lastInput = inputs.last();
        /*redirect last tab to first input*/
        inputs.on('keydown', function (e) {
          var elements = $(this)
            .closest('.overlay')
            .find(focusElements)
            .filter(':visible'),
            firstInput = elements.first(),
            lastInput = elements.last();


          if (e.target === firstInput[0]) {
            if (e.which === 9 && e.shiftKey) {
              e.preventDefault();
              lastInput.focus();
            }
          }
          if (e.target === lastInput[0]) {
            if (e.which === 9 && !e.shiftKey) {
              e.preventDefault();
              firstInput.focus();
            }
          }
        });
      });
    }

    $('form, .degree-finder__filter').on(
      'keydown',
      '#people-search',
      function (event) {
      }
    );

    $(window).on('resize orientationchange', resizeHandler);
    $('.section img')
      .on('load', function (event) {
        resizeHandler();
        $(this).off('load');
      })
      .each(function () {
        if (this.complete) {
          $(this).load();
        }
      });
    resizeHandler();
    setTimeout(function () {
      resizeHandler();
    }, 1000);
  }

  if ($('input[name="zipcode"]').length) {
    $("input[name='student-type']").change(function (e) {
      e.stopPropagation();
      setZicodeAttibutes($(this).filter(':checked').val());
      $('input[name="zipcode"]').val('');
    });
  }

  function setZicodeAttibutes(zipCodeVal) {
    const $zipcodeField = $('input[name="zipcode"]');

    const allowNumbers = function (e) {
      var key = e.keyCode ? e.keyCode : e.which;

      if (
        !(
          [8, 9, 13, 27, 46, 110, 190].indexOf(key) !== -1 ||
          (key == 65 && (e.ctrlKey || e.metaKey)) ||
          (key >= 35 && key <= 40) ||
          (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
          (key >= 96 && key <= 105)
        )
      )
        e.preventDefault();
    };

    if (zipCodeVal === 'domestic-student') {
      $zipcodeField
        .attr('type', 'text')
        .attr('maxlength', '5')
        .attr('pattern', '^[0-9]{5}$');
      $zipcodeField.keydown(allowNumbers);
      $("input[value='domestic-student']").prop('checked', 'true');
    } else {
      $zipcodeField
        .attr('type', 'text')
        .attr('pattern', '^[a-zA-Z0-9\\s-]{1,16}$')
        .attr('maxlength', '16')
        .attr('data-allownumbers', true);
      $zipcodeField.off('keydown');
      $("input[value='international-student']").prop('checked', 'true');
    }
  }

  Core.addATCEvents = function () {
    addeventatc.settings({
      license: 'atHhmszCEzJafqecWmwH30254',
      mouse: true,
      css: false,
      outlook: { show: true, text: 'Outlook' },
      google: { show: true, text: 'Google <em>(online)</em>' },
      yahoo: { show: true, text: 'Yahoo <em>(online)</em>' },
      outlookcom: { show: true, text: 'Outlook.com <em>(online)</em>' },
      appleical: { show: true, text: 'Apple Calendar' },
      facebook: { show: true, text: 'Facebook Event' },
      dropdown: { order: 'outlook,google,appleical,yahoo,outlookcom' },
    });
    addeventatc.register('button-dropdown-show', function (obj) {
      var ranID = new Date().getTime();
      $('#' + obj.id).attr(
        'aria-label',
        $('#' + obj.id)
          .find('.drop_markup')
          .text() +
        ', Use up/down keys to choose calendar type, Enter to select'
      );
      $('#' + obj.id).on('keyup', function (event) {
        $(this).attr(
          'aria-label',
          $(this).find('.drop_markup').text() +
          ', Use up/down keys to choose calendar type, Enter to select'
        );
      });
    });
    addeventatc.register('button-dropdown-hide', function (obj) {
      if (obj.id) {
        $('#' + obj.id).attr('aria-label', '');
        $('#' + obj.id).off('keyup');
      }
    });
  };

  //MENU EVENTS
  var navGlobal = $('.nav__global'),
    navSearchOpen = $('.nav__search-open');

  $('.search-button--open').on('click', function () {
    /* hide search close icon when search button glass is activated */
    $('.search-button--close').show();
    $('.search-button--open').addClass('search-clicked-open').hide();
    $('.mobile-menu-button--open').removeClass('menu-clicked-open').show();
    $('.mobile-menu-button--close').hide();

    swapMobileMenuActiveIcons();

    $('.nav__global').removeClass('open appear-from-right nav__subnav--open');
    $('.search__global').toggleClass('search-open appear-from-right');
    $('.mobileSearch').focus();
    $('.mobileSearch').trigger('click');
  });

  $('.search-button--close').on('click', function () {
    /* hide search glass icon when search close glass is activated */
    $('.search-button--open').removeClass('search-clicked-open').show();
    $('.search-button--close').hide();

    swapMobileMenuActiveIcons();

    $('.nav__global').removeClass('open appear-from-right nav__subnav--open');
    $('.search__global').toggleClass('search-open appear-from-right');
  });

  //MENU EVENTS
  $('.mobile-menu-button--open').on('click', function () {
    $('.mobile-menu-button--close').show();
    $('.mobile-menu-button--open').addClass('menu-clicked-open').hide();
    $('.search-button--open').removeClass('search-clicked-open').show();
    $('.search-button--close').hide();

    swapSearchActiveIcons();

    $('.search__global').removeClass('search-open appear-from-right');
    navGlobal.addClass('open appear-from-right');
  });

  $('.mobile-menu-button--close').on('click', function () {
    $('.mobile-menu-button--close').hide();
    $('.mobile-menu-button--open').removeClass('menu-clicked-open').show();

    swapSearchActiveIcons();

    $('.search__global').removeClass('search-open appear-from-right');
    navGlobal.removeClass('open appear-from-right nav__subnav--open');
  });

  // FUNCTIONS NEW MENU
  function swapMobileMenuActiveIcons() {
    if ($('.mobile-menu-button--open').hasClass('menu-clicked-open')) {
      $('.mobile-menu-button--close').show();
      $('.mobile-menu-button--open').hide();
    } else {
      $('.mobile-menu-button--close').hide();
      $('.mobile-menu-button--open').show();
    }
  }

  function swapSearchActiveIcons() {
    if ($('.search-button--open').hasClass('search-clicked-open')) {
      $('.search-button--open').hide();
      $('.search-button--close').show();
    } else {
      $('.search-button--open').show();
      $('.search-button--close').hide();
    }
  }

  function initMap() {
    let zipcodeLocal = window.localStorage.getItem('z');
    let countryLocal = window.localStorage.getItem('country');
    if (zipcodeLocal === null) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          $.ajax({
            type: 'GET',
            async: false,
            url: settings.reverseURL,
            data: {
              latlng: pos.lat + ',' + pos.lng,
              key: settings.mapskey,
            },
            dataType: 'json',
            error: function (data) {
            },
            success: function (data) {
              if (data.status === 'OK') {
                let country = [...data.results].pop();
                country = country['formatted_address'];

                window.localStorage.setItem('country', country);
                $.cookie('country', country, {
                  expires: 30,
                  secure: true,
                  path: '/',
                });
                country === 'United States'
                  ? ($("input[value='domestic-student']").prop(
                    'checked',
                    'true'
                  ),
                    setZicodeAttibutes('domestic-student'))
                  : ($("input[value='international-student']").prop(
                    'checked',
                    'true'
                  ),
                    setZicodeAttibutes('international-student'));

                data.results.forEach(function (element) {
                  element.address_components.forEach(function (element) {
                    if (element.types.indexOf('postal_code') > -1) {
                      zipcode = element.short_name;
                      if (!zipcode) {
                        zipcode = '92618';
                      }
                      window.localStorage.setItem('z', zipcode);
                      $.cookie('zipcode', zipcode, {
                        expires: 30,
                        secure: true,
                        path: '/',
                      });
                      if ($el.locationZipcode.length) {
                        $el.locationZipcode.text(zipcode);
                        $el.locationZipcodeReader.text(
                          zipcode.split('').join(' ')
                        );
                      }
                      $el.locationField.val(zipcode).change();
                      ///******* if location finder fire search after zip is loaded ************////////
                      if ($('.location-finder').length) {
                        $('.degree-finder__filter .btn').trigger('click');
                      }
                    }
                  });
                });
              }
            },
          });
        },
        function (error) {
          if ($('input[name="zipcode"]').length) {
            $("input[value='domestic-student']").prop('checked', 'true');
            setZicodeAttibutes('domestic-student');
          }
        }
      );
    } else {

      if (zipcodeLocal === "") {
        window.localStorage.setItem('z', '92618');
        $.cookie('zipcode', '92618', {
          expires: 30,
          secure: true,
          path: '/',
        });
        if ($el.locationZipcode.length) {
          $el.locationZipcode.text('92618');
          $el.locationZipcodeReader.text(
            '92618'.split('').join(' ')
          );
        }
        $el.locationField.val('92618').change();
        $('.location__field').text('92618');
        $('.location__field').val('92618').change();
        $('.location__field').val('92618');
        ///******* if location finder fire search after zip is loaded ************////////
        if ($('.location-finder').length) {
          $('.degree-finder__filter .btn').trigger('click');
        }
      }

      if ($el.locationZipcode.length) {
        $el.locationZipcode.text(zipcodeLocal);
        $el.locationZipcodeReader.text(zipcodeLocal.split('').join(' '));
      }
      $el.locationField.val(zipcodeLocal).change();
      ///******* if location finder fire search after zip is loaded ************////////
      if ($('.location-finder').length) {
        $('.degree-finder__filter .btn').trigger('click');
      }

      if ($('input[name="zipcode"]').length) {
        $('input[name="zipcode"]').val(zipcodeLocal);
        countryLocal === 'United States'
          ? ($("input[value='domestic-student']").prop('checked', 'true'),
            setZicodeAttibutes('domestic-student'))
          : ($("input[value='international-student']").prop('checked', 'true'),
            setZicodeAttibutes('international-student'));
      }
    }


  }

  /**
   *   Public Methods
   **/

  Core.zipcode = zipcode;

  Core.init = function () {
    setup();
    bindEvents();
    initMap();
  };
})((window.BDM = window.BDM || {}), (window.BDM.Core = window.BDM.Core || {}));

//load when ready
$(function () {
  window.BDM.Core.init();
});
